<template>
    <div class="box">
        <company-info :hasFilter="true" :navLists="navList" :navCurrentIds="navCurrentId"></company-info>
        <!-- 筛选tab -->
        <div class="homepage-content">
            <div class="meeting-filter">
                <filter-tab v-if="provinceList.length" type="special" title="省份" @clickItem="handleFilterEvent('provinceIndex', $event)"
                    :isShowMore="true" :list="provinceList"></filter-tab>
                <filter-tab v-if="subjectList.length" type="special" title="学科" @clickItem="handleFilterEvent('subjectIndex', $event)"
                    :isShowMore="true" :list="subjectList"></filter-tab>
                <filter-tab title="年份" type="special" @clickItem="handleFilterEvent('yearIndex', $event)" :list="yearList"></filter-tab>
                <filter-tab title="月份" type="special" @clickItem="handleFilterEvent('monthIndex', $event)" :list="monthList"></filter-tab>
            </div>
            <div class="flex mt20 meeting-box">
                <div class="homepage-list">
                    <div class="meeting-list">
                        <a-spin :spinning="loading" tip="Loading...">
                            <div class="meeting-item" :key="index" v-for="(item, index) in meetingList"
                                @click="meetJumpDetail(item.href, item.id)">
                                <across-media-item :item="item">
                                    <template v-slot:desc>
                                        <span class="meeting-item-address">
                                            <span v-if="item.start_time" class="color999 fs14"><i
                                                    class="med med-31shijian"></i>{{ item.start_time.substr(0, 10) }}</span>
                                            <span v-if="item.address" class="color999 ml20 fs14"><i
                                                    class="med med--didian"></i>{{ item.address }}</span>
                                        </span>
                                    </template>
                                    <template v-slot:bottom>
                                        <div class="flex x-left flex-wrap meeting-item-link">
                                            <a v-if="item.is_open_live == 1" class="btn-link btn-link-7"
                                                @click.stop="goRecordInfoPage('live', item)">观看直播</a>
                                            <a v-if="item.is_open_live == 2" class="btn-link btn-link-7"
                                                @click.stop="goRecordInfoPage('live_appoint', item)">
                                                {{ item.is_appointment == 1 ? '已预约直播' : '预约直播' }}
                                            </a>
                                            <a v-if="item.is_open_program_duty == 1" class="btn-link btn-link-8"
                                                @click.stop="goRecordInfoPage('academic', item)">学术任务</a>
                                            <a v-if="item.is_statement == 1" class="btn-link btn-link-6"
                                                @click.stop="goRecordInfoPage('Undertaking', item)">防疫承诺书</a>
                                            <a v-if="item.is_invoice == 1" class="btn-link btn-link-5"
                                                @click.stop="goRecordInfoPage('invoice', item)">电子发票</a>
                                            <!-- <a v-if="item.open_search_cert == 1" class="btn-link">学分下载</a> -->
                                            <a v-if="item.is_open_meeting_video == 1" class="btn-link btn-link-4"
                                                @click.stop="goRecordInfoPage('video', item)">视频回放</a>
                                            <!-- 参会注册和签到票暂未用到这个参数 && item.is_meeting_reg == 0-->
                                            <a v-if="item.is_open_meeting_reg == 1" class="btn-link btn-link-3"
                                                @click.stop="goRecordInfoPage('reg', item)">参会注册</a>
                                            <a v-if="item.is_open_meeting_reg == 1 && item.is_meeting_reg_type == 1"
                                                class="btn-link btn-link-2"
                                                @click.stop="goRecordInfoPage('signIn', item)">签到票</a>
                                            <a v-if="item.is_open_abstract == 1" type="2" class="btn-link  btn-link-1"
                                                @click.stop="goRecordInfoPage('paper', item)">论文投稿</a>
                                            <a class="btn-link btn-link-0"
                                                @click.stop="goRecordInfoPage('person', item)">个人中心</a>
                                        </div>
                                    </template>
                                </across-media-item>
                            </div>
                            <a-empty style="margin-top:100px;" v-if="!meetingList.length && !loading" />
                        </a-spin>
                        <div class="t-c mt40 meet-pagination" v-if="meetTotal > 0">
                            <a-pagination :total="meetTotal" v-model:current="params.page" :page-size="params.pageSize" show-quick-jumper @change="handlePageChangeEvent" :hideOnSinglePage="true" />
                        </div>
                    </div>
                </div>
                <div class="homepage-right t-l">
                    <layout-right :config="layoutRightConfig"></layout-right>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import { companyNavList } from "@/config/const";
import companyInfo from '@/components/company-info.vue';
import Header from "@/components/layout/layout-header";
import Footer from "@/components/layout/layout-footer";
import layoutRight from '@/components/layout/layout-right';
import filterTab from '@/components/filter-tab';
import acrossMediaItem from '@/components/across-media-item';
import { meetJumpDetail, goRecordInfoPage } from '@/utils/jumpPageMethods';
import { jsonFormat } from '@/utils/jsonFormat';

export default {
    name: "index",
    components: {
        Header, Footer, layoutRight, filterTab,acrossMediaItem,companyInfo
    },
    data() {
        return {
            navList: companyNavList(this.$route.query.company_id),//顶部菜单
            navCurrentId: 3,//当前页面菜单对应下标
            provinceIndex: -1,
            subjectIndex: -1,
            yearIndex: -1,
            monthIndex: -1,
            provinceList: [],
            subjectList: [],
            yearList: [],
            monthList: [],
            params:{
                page: 1,
                pageSize: 10,
                committee_id: this.$route.query.company_id
            },
            meetTotal: 0,
            meetingList: [],
            loading: true,
            layoutRightConfig: {
                news: {
                    show: true, // 是否显示
                    methods: 'post',
                    title: '资讯', // 标题
                    moreLink: '/company/news?company_id=' + this.$route.query.company_id,
                    detailLink: '/company/news-detail?company_id='+this.$route.query.company_id+'&pageType=news',
                    data: {
                        page: 1,
                        limit: 5,
                        committee_id:this.$route.query.company_id,
                        type : 5,//必传
                        is_verify : 1,//必传 https://docs.qq.com/doc/DY2lGaXdVelRrQnJP
                    },   // 参数条件
                },
                live: { //直播
                    show: true, // 是否显示
                    methods: 'get',
                    moreLink:'/company/live?company_id=' + this.$route.query.company_id,
                    title: '直播', // 标题
                    data: {
                        page: 1,
                        limit: 2,
                        committee_id: this.$route.query.company_id,
                        no_end_time: 1
                    },   // 参数条件
                },
                orgMeetback: {
                    show: true, // 是否显示
                    title: '视频', // 标题
                    methods: 'get',
                    moreLink:'/company/video?company_id=' + this.$route.query.company_id,
                    data: {
                        page: 1,
                        limit: 3,
                        committee_id_search: this.$route.query.company_id,
                    },   // 参数条件
                },
            },
        }
    },
    created() {
        this.getYearList()
        this.getMonthList()
        this.pageInit()
    },
    methods: {
        meetJumpDetail,
        goRecordInfoPage,
        getRegion() {
            return this.request.get('GetRegion')
        },
        getSubject() {
            return this.$store.dispatch('getSubjectList')
        },
        // 获取年份
        getYearList() {
            let yearList = [];
            let currYear = (new Date()).getFullYear();
            // 加上过年的
            let yearLen = currYear + 1;
            for (let i = yearLen; i >= 2018; i--) {
                yearList.push({ id: i, name: i + '年' });
            }
            this.yearList = yearList;
        },
        // 获取月份
        getMonthList() {
            let monthList = [];
            for (let i = 1; i <= 12; i++) {
                monthList.push({ id: i, name: i + '月' });
            }
            this.monthList = monthList;
        },
        // 获取会议列表
        getMeetingList() {
            this.loading = true
            this.request.get('GetMeetingList', this.params).then(res => {
                let meetObj = {
                    title: 'cn_title',
                    img: 'pic_url'
                }
                this.meetTotal = Number(res.data.count)
                this.meetingList = jsonFormat(res.data.data, meetObj)
                this.loading = false
            })
        },pageInit() {
            this.loading = true
            let reqArr = [
                this.getRegion(),
                this.getSubject(),
                this.request.get('GetMeetingList', this.params)
            ]
            Promise.all(reqArr).then(res => {
                // 省份列表
                let regionObj = {
                    name: 'Name',
                    id: 'ID'
                }
                this.provinceList = jsonFormat(res[0].data, regionObj)

                // 学科列表
                let subjectObj = {
                    name: 'operate_name',
                }
                this.subjectList = jsonFormat(res[1].data, subjectObj)

                // 会议列表
                let meetObj = {
                    title: 'cn_title',
                    img: 'pic_url'
                }
                this.meetTotal = Number(res[2].data.count)
                this.meetingList = jsonFormat(res[2].data.data, meetObj)

                this.loading = false
            }).catch((err) => { console.log(err) })
        },
        // 筛选切换事件
        handleFilterEvent(key, e) {
            this.params.page = 1
            key && (this[key] = e);
            if (this.provinceIndex != -1) {
                this.params.province = this.provinceList[this.provinceIndex].id
            } else {
                delete this.params.province
            }
            if (this.subjectIndex != -1) {
                this.params.subject_ids = this.subjectList[this.subjectIndex].subject_ids
            } else {
                delete this.params.subject_ids
            }
            if (this.yearIndex != -1) {
                this.params.year = this.yearList[this.yearIndex].name.split("年")[0]
            } else {
                delete this.params.year
            }
            if (this.monthIndex != -1) {
                this.params.month = this.monthList[this.monthIndex].name.split("月")[0]
            } else {
                delete this.params.month
            }
            this.getMeetingList()

        },
        // 切换页码
        handlePageChangeEvent(page) {
            window.scrollTo(0, 0);
            this.params.page = page
            this.getMeetingList()
        },
    }
}
</script>

<style scoped lang="less">

.homepage-header {
    width: 1200px;
    margin: 0 auto;

    &-title {
        max-width: 200px;
    }

    &-img {
        width: 88px;
        height: 88px;
        background: red;
    }
}
.homepage-content {
    width: 1240px;
    margin: 0 auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
}
.homepage-list {
    width: 800px;
    margin-right: 30px;

    .mh {
        min-height: 500px;
    }

    .filter-condition {
        background: #f2f4fa;
        overflow: hidden;
        height: 8px;

        &.active {
            height: 50px;
            line-height: 50px;
        }
    }

    .meeting-list {
        width: 100%;
        min-height: 600px;
        margin-right: 30px;

        .meeting-item {
            margin-bottom: 20px;
            border-bottom: 1px dashed #D8D8D8;

            &:last-child {
                border: 0;
            }
        }

        .btn-link {
            color: #999999;
            font-size: 12px;
            padding: 4px 20px;
            border: 1px solid #f9f9f9;
            // background: #f9f9f9;
            border-radius: 3px;
            margin-top: 5px;
            margin-right: 5px;
            flex-shrink: 0;

            &:hover {
                background: linear-gradient(to right, #5f96e6, #1e51c9);
                color: #fff;
            }
        }

        .btn-link-0 {
            border-color: #3e7eaf;
            color: #3e7eaf;

            &:hover {
                background: #3e7eaf;
            }
        }

        .btn-link-1 {
            border-color: #8e35a9;
            color: #8e35a9;

            &:hover {
                background: #8e35a9;
            }
        }

        .btn-link-2 {
            border-color: #488c5f;
            color: #488c5f;

            &:hover {
                background: #488c5f;
            }
        }

        .btn-link-3 {
            border-color: #eea952;
            color: #eea952;

            &:hover {
                background: #eea952;
            }
        }

        .btn-link-4 {
            border-color: #5461c9;
            color: #5461c9;

            &:hover {
                background: #5461c9;
            }
        }

        .btn-link-5 {
            border-color: #709b1d;
            color: #709b1d;

            &:hover {
                background: #709b1d;
            }
        }

        .btn-link-6 {
            border-color: #a93553;
            color: #a93553;

            &:hover {
                background: #a93553;
            }
        }

        .btn-link-7 {
            border-color: #fc7647;
            color: #fc7647;

            &:hover {
                background: #fc7647;
            }
        }

        .btn-link-8 {
            border-color: #f7cf46;
            color: #f7cf46;

            &:hover {
                background: #f7cf46;
            }
        }
    }
}

.homepage-right {
    width: 400px;
}

.meeting-filter {
    .filter-box {
        border-bottom: 0;
    }
}
@media screen and (max-width:768px){
    /deep/.mobile-mt{
        margin-top: 195px;
    }
    .homepage-content {
        width: 100%;
        padding: 0;

        .meeting-filter {
            width: 100%;
            height: 190px;
            position: fixed;
            background: #fff;
            z-index: 99999;
            padding: 0;
            top: 50px;
            left: 0;

            .filter-box:not(:last-child) {
                border-bottom: 1px solid #f5f5f5 !important;
            }
        }

        .meeting-box {
            width: 100%;
            margin-top: 0;
            padding: 10px;
            display: block;

            .homepage-list {
                width: 100%;
                display: block;

                .meeting-list {
                    width: 100%;
                    min-height: 0;

                    /deep/.ant-empty {
                        margin-top: 0 !important;
                    }

                    .meeting-item {
                        &-address {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box !important;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                        }

                        &-link {
                            overflow-x: scroll;
                            flex-wrap: nowrap;
                        }
                    }

                    .meet-pagination {
                        margin-top: 20px;
                    }
                }
            }

            .homepage-right {
                width: 100%;
                margin-top: 50px;
            }
        }
    }
}
</style>