<template>
    <div class="box">
        <div class="background"></div>
        <Header :navList="navList" :navCurrentId="navCurrentId" :search-style="{ width: 150 }" :typeList="typeList"
            :logo="logoUrl">
            <div slot="search">
                <div style="width:200px;"></div>
            </div>
        </Header>
        <div class="enter-box enter-box1 mb20 flex x-left y-center">
            <img v-if="allowForm" class="img-tips mr20" src="https://files.sciconf.cn/medcon/2021/07/20210730/2021073014073938521069417.jpg"
                alt="">
            <div class="text-tips">
                <div class="fs20 strong t-l">入驻申请填写</div>
                <div v-if="allowForm" class="fs16 t-l mt10 color666">我们诚邀您入驻会务通医企秀平台。我们将用我们的平台优势，为您整合数据，推送相关您关注的会议信息。</div>
                <template v-else>
                    <div v-if="verify_status == 1" class="status-txt mt10 t-l">您的信息还在<span class="strong" style="color:#1890ff;">审核中</span>，请您留意电话，我们会有专人在24小时内与您联系。</div>
                    <div v-if="verify_status == 2" class="status-txt status-error mt10 t-l">
                        {{ audit_opinion || '审核失败' }}
                    </div>
                    <div v-if="verify_status == 3" class="status-txt status-success mt10 t-l">
                        {{ audit_opinion || '审核成功' }}
                    </div>
                </template>
            </div>
        </div>
        <div class="enter-box">
                <a-form class="mt30 retrieve-form-item" ref="ruleForm" :form="form" :label-col="labelCol"
                :wrapper-col="wrapperCol">
                <a-form-item label="公司名称">
                    <a-input placeholder="请填写贵公司名称/机构名称" :disabled="!allowForm"
                        v-decorator="['name', { rules: [{ required: true, message: '公司名称不能为空' }] }]"></a-input>
                </a-form-item>
                <a-form-item label="联系人">
                    <a-input placeholder="请填写您的名字" :disabled="!allowForm"
                        v-decorator="['linkman', { rules: [{ required: true, message: '联系人不能为空' }] }]"></a-input>
                </a-form-item>
                <a-form-item label="手机号码">
                    <a-input placeholder="请填写您的手机号码" :disabled="!allowForm"
                        v-decorator="['mobile', { rules: [{ required: true, message: '手机号码不能为空' }, { pattern: /^1[\d]{10}$/, message: '请填写正确的联系电话' }] }]"></a-input>
                </a-form-item>
                <a-form-item label="电子邮箱">
                    <a-input placeholder="请填写您的电子邮箱" :disabled="!allowForm"
                        v-decorator="['email', { rules: [{ required: true, message: '电子邮箱不能为空' }, { pattern: /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/, message: '请填写正确的电子邮箱' }] }]"></a-input>
                </a-form-item>
                <a-form-item label="所属省市">
                    <div class="flex x-left">
                        <a-select style="width:32%;" :disabled="!allowForm" placeholder="请选择省份" @change="handleProvinceChange"
                            v-decorator="['province_id', { rules: [{ required: true, message: '请选择省份' }] }]">
                            <a-select-option v-for="(province, index) in provinceData" :key="index"
                                :value="province.id">
                                {{ province.name }}
                            </a-select-option>
                        </a-select>
                        <template v-if="cityList && cityList.length">
                            <a-select :disabled="!allowForm" style="width:32%;margin-left:2%;" placeholder="请选择城市" @change="handleCityChange"
                                v-decorator="['city_id', { rules: [{ required: true, message: '请选择城市' }] }]">
                                <a-select-option v-for="(city, index) in cityList" :key="city.id" :value="city.id">
                                    {{ city.name }}
                                </a-select-option>
                            </a-select>
                            <template v-if="areaList && areaList.length">
                                <a-select :disabled="!allowForm" style="width:32%;margin-left:2%;" placeholder="请选择区" @change="handleAreaChange"
                                    v-decorator="['area_id', { rules: [{ required: true, message: '请选择区' }] }]">
                                    <a-select-option v-for="(area, index) in areaList" :key="area.id" :value="area.id">
                                        {{ area.name }}
                                    </a-select-option>
                                </a-select>
                            </template>
                        </template>
                    </div>
                </a-form-item>
                <a-form-item label="单位地址">
                    <a-input placeholder="请填写贵单位/机构的地址" :disabled="!allowForm"
                        v-decorator="['address', { rules: [{ required: true, message: '单位地址不能为空' }] }]"></a-input>
                </a-form-item>
                <a-form-item label="侧重领域" v-if="allowForm">
                    <a-select mode="multiple" :disabled="!allowForm" placeholder="请选择公司产品相关类目" v-decorator="['subject_class',{rules:[{required:true,message:'请选择公司产品相关类目'}]}]" @change="handleSubjectChange">
                        <template v-for="(subject,index) in subjectList">
                            <a-select-opt-group v-if="subject.id" :key="index">
                                <span slot="label" class="strong color000 fs14">{{ subject.Name }}</span>
                                <template v-if="subject.child.length">
                                    <a-select-option :code="child" :value="child.Name" v-for="(child,indexs) in subject.child" :key="child.id">{{ child.Name }}</a-select-option>
                                </template>
                            </a-select-opt-group>
                        </template>
                    </a-select>
                </a-form-item>
                <a-form-item label="公司介绍">
                    <a-textarea :disabled="!allowForm" placeholder="请输入公司介绍" :auto-size="{ minRows: 3, maxRows: 5 }"
                        v-decorator="['desc', { rules: [{ required: true, message: '公司简介不能为空' }] }]"></a-textarea>
                </a-form-item>
                <div class="img-upload">
                    <a-form-item label="营业执照" >
                        <a-upload
                            list-type="picture-card"
                            class="avatar-uploader"
                            :show-upload-list="false"
                            :action="uploadImageUrl"
                            :before-upload="beforeUpload"
                            :headers="uploadHeaders"
                            @change="handleBusinessImgChange($event,'business_img')"
                        >   
                        <input type="hidden" v-decorator="['business_img', { rules: [{ required: true, message: '请上传营业执照' }] }]">
                            <template v-if="form.getFieldValue('business_img')">
                                <img :src="'https://files.sciconf.cn/'+form.getFieldValue('business_img') | urlFilter(250)" alt="business_img" />
                            </template>
                            <img v-else src="https://files.sciconf.cn/medcon/2021/07/20210728/2021072815134994372681510.png" alt="">
                            <div class="upload-tips" v-if="allowForm">拍摄/上传 营业执照</div>
                        </a-upload>
                        <div class="upload-txt fs12 color999 t-l" v-if="allowForm">上传大小限制在5M以内，图片格式为JPG.JPEG.PNG</div>
                    </a-form-item>
                    <a-form-item label="公司LOGO" >
                        <a-upload
                            list-type="picture-card"
                            class="avatar-uploader"
                            :show-upload-list="false"
                            :action="uploadImageUrl"
                            :before-upload="beforeUpload"
                            :headers="uploadHeaders"
                            @change="handleBusinessImgChange($event,'logo')"
                        >
                            <input type="hidden" v-decorator="['logo', { rules: [{ required: true, message: '请上传公司LOGO' }] }]">
                            <template v-if="form.getFieldValue('logo')">
                                <img :src="'https://files.sciconf.cn/'+form.getFieldValue('logo') | urlFilter(250)" alt="logo" />
                            </template>
                            <img v-else src="https://files.sciconf.cn/medcon/2021/07/20210728/2021072815134994372681510.png" alt="">
                            <div class="upload-tips" v-if="allowForm">拍摄/上传 LOGO</div>
                        </a-upload>
                        <div class="upload-txt fs12 color999 t-l" v-if="allowForm">上传大小限制在5M以内,图片格式为JPG.JPEG.PNG,图片尺寸380*300PX</div>
                    </a-form-item>
                </div>
                
                <a-form-item class="submit-btn" label="" v-if="allowForm">
                    <div class="btn" @click="onSubmit">提交申请</div>
                </a-form-item>
            </a-form>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
let fields = ['name', 'linkman', 'mobile','address', 'email', 'province_id', 'city_id', 'area_id','subject_class','desc','business_img','logo']
import { organizationList, organizationLogo } from "@/config/const";
import Header from "@/components/layout/layout-header";
import Footer from "@/components/layout/layout-footer";
import userApi from '@/request/apiList';
import { key } from '@/config'
export default {
    name: "HospitalList",
    components: {
        Header, Footer
    },
    data() {
        return {
            logoUrl: organizationLogo,
            navList: organizationList,//顶部菜单
            navCurrentId: 3,//当前页面菜单对应下标
            typeList: [],
            form: this.$form.createForm(this),
            uploadImageUrl:userApi.UploadAvatar,
            uploadHeaders:{
                key: key
            },
            // 表单响应式布局
            labelCol: {
                xs: { span: 24 }, sm: { span: 4 }, lg: { span: 4 }, xl: { span: 2 }
            },
            wrapperCol: {
                xs: { span: 24 }, sm: { span: 20 }, lg: { span: 20 }, xl: { span: 16 }
            },
            provinceData: [],
            cityList: [],
            areaList: [],
            subjectList:[],
            business_img:'',
            allowForm:false,
            verify_status:'', //审核状态
            audit_opinion:'', //审核意见
        }
    },
    created() {
        // 防止表单未注册
        fields.forEach(v => this.form.getFieldDecorator(v))
        this.getCommitteeStatus()
        this.getAddress()
        this.getSubjectList()
        
    },

    methods: {
        filterArray(arr, id, type) {
            return arr.find(item => {
                if (item.id == id) {
                    return type == 'list' ? item.list : item.name
                }
            })
        },
        // 申请入驻 获取入驻状态
        getCommitteeStatus(){
            /**获取审核状态，
                有值
                    verify_status ： 审核状态 1=审核中 2=审核不通过 3=审核通过
                    audit_opinion :  审核意见
                没值
                    直接入驻  
            */ 
            this.request.post('CommitteeStatus').then(res=>{
                if(Object.keys(res.data).length > 0){
                    this.allowForm = false
                    this.verify_status = res.data.verify_status
                    this.audit_opinion = res.data.audit_opinion
                    res.data.region = [res.data.province_id,res.data.city_id,res.data.area_id];//省市区处理
                    res.data.subject_class = res.data.subject_name
                    fields.forEach((item,index)=>{
                        
                        if(res.data.province_id){
                            let id = ''
                            this.provinceData.forEach(items=>{
                                if(items.name == res.data.province_id){
                                    id = items.id
                                    this.$nextTick(() => {
                                        this.provinceData && this.form.setFieldsValue({ 'province_id': items.name })
                                    })
                                }
                            })
                            this.cityList = this.filterArray(this.provinceData, id, 'list')?.list
                            this.$nextTick(() => {
                                this.cityList && this.form.setFieldsValue({ 'city_id': this.cityList[0].name })
                            })
                            this.cityList[0]?.id && (this.areaList = this.filterArray(this.cityList, this.cityList[0]?.id, 'list')?.list)
                            this.$nextTick(() => {
                                this.areaList && this.form.setFieldsValue({ 'area_id': this.areaList[0].name })
                            })
                            
                        }
                        this.$nextTick(() => {
                            this.form.setFieldsValue({[item]:res.data[item]})
                        })
                    })
                }else{
                    this.allowForm = true
                }
            })
        },
        getAddress() {
            this.request.get('address').then(res => {
                if (res && res.indexOf("layui.define(function(exports){") > -1) {
                    let a = res.split('var addressList = ')[1]
                    a = a.replace(/^\s+|\s+$/g, '')
                    a = a.split("exports('cityJson',addressList);")[0]
                    a = a.replace(/\ /g, "").replace(';', '')
                    let cityList = JSON.parse(a)
                    this.provinceData = cityList[42].list
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        handleProvinceChange(e) {
            this.provinceData.forEach(item=>{
                if(item.id == e){
                    this.$nextTick(() => {
                        this.provinceData && this.form.setFieldsValue({ 'province_id': item.name })
                    })
                    
                }
            })

            this.cityList = this.filterArray(this.provinceData, e, 'list')?.list
            this.$nextTick(() => {
                this.cityList && this.form.setFieldsValue({ 'city_id': this.cityList[0].name })
            })

            this.cityList[0]?.id && (this.areaList = this.filterArray(this.cityList, this.cityList[0]?.id, 'list')?.list)
            this.$nextTick(() => {
                this.areaList && this.form.setFieldsValue({ 'area_id': this.areaList[0].name })
            })
        },
        handleCityChange(e) {
            this.areaList = this.filterArray(this.cityList, e, 'list').list
            this.$nextTick(() => {
                this.areaList && this.form.setFieldsValue({ 'area_id': this.areaList[0].name })
            })
        },
        handleAreaChange() {

        },
        getSubjectList(){
            this.request.get('GetSubject').then(res=>{
                this.subjectList = res.data
            })
            
        },
        handleSubjectChange(e,option){
            let json = []
            option.forEach(item=>{
                let data = item.data.attrs.code
                json.push(
                    {
                        name : data.Name,
                        id : data.id,
                        subject_id : data.parent_id + '-' + data.id
                    }
                )
            })
            this.$nextTick(() => {
                this.json = json
            })
        },
        handleBusinessImgChange(info,type) {
            if (info.file.status === 'uploading') {
                return false
            }
            if (info.file.status === 'done') {
                let { code, data, message } = info.file.response;
                if (code == 200) {
                    let obj = {};
                    obj[type] = data.relative_path
                    this.form.setFieldsValue(obj)
                } else {
                    this.$message.error(message)
                }
            }
        },
        // 上传前
        beforeUpload(file) {
            return new Promise((resolve, reject) => {
                let regFile = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
                let regSize = true
                // 验证大小 KB为单位
                const isLt5M = file.size / 1024 / 1024 < 5;
                if (!isLt5M) {
                    regSize = false
                }

                return resolve(regFile && regSize);
            })
        },
        onSubmit() {
            this.form.validateFields((errors, values) => {
                if (!errors) {
                    values.subject_class = JSON.stringify(this.json)
                    this.request.post('ApplyCommittee',values).then(res => {
                        if(res.code == 200){
                            this.getCommitteeStatus()
                        }else{
                            this.$message.error(res.Message)
                        }
                        
                    })
                }
            })
        }

    }
}
</script>

<style scoped lang="less">
.background {
    width: 100%;
    height: 500px;
    background: linear-gradient(360deg, rgba(234, 249, 255, 0) 0%, #EAFAFF 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.enter-box {
    width: 100%;
    max-width: 1240px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px;
    margin: 20px auto 0;

    // box-shadow: 0px 2px 12px 0px rgba(3,0,0,.1);
    .img-tips {
        height: 150px;
    }
    .status-txt{
        padding: 10px;
        background-color: #e6f7ff;
        border: 1px solid #91d5ff;
    }
    .status-error{
        background-color: #fff2f0;
        border: 1px solid #ffccc7;
        color: #ff4d4f;
    }
    .status-success{
        background-color: #f6ffed;
        border: 1px solid #b7eb8f;
        color:#52c41a;
    }

    .submit-btn {
        width: 300px;
        height: 40px;
        border-radius: 50px;
        background: #1E51C9;
        color: #ffffff;
        text-align: center;
        margin-left: 20%;
        cursor: pointer;

        /deep/.ant-form-item-control-wrapper {
            width: 100%;
        }

        .btn {
            width: 100%;
            font-size: 16px;
            font-weight: bold;
        }
    }

    /deep/.ant-form-explain {
        text-align: left;
    }
    /deep/.ant-upload{
        border: 0;
        background: #f5f8fd;
        border-radius: 5px;
        overflow: hidden;
        width: 182px;
        height: 158px;
        img{
            width: 166px;
            height: 110px;
            display: block;
            object-fit: contain;
        }
    }
    .upload-tips{
        width: calc(100% + 16px);
        height: 30px;
        margin-top: 10px;
        margin-bottom: -8px;
        margin-left: -8px;
        background: #719af8;
        color: #ffffff;
        line-height: 30px;
    }
    .upload-txt{
        margin-top: -10px;
        line-height: 20px;
    }
}

@media screen and (max-width:768px) {
    .enter-box {
        width: 100%;
        margin: 0;
        padding: 10px;
        border-radius: 0;
        .submit-btn{
            margin: 0 auto;
        }
    }
    .enter-box1{
        margin-bottom: 15px;
        display: block;
        img{
            width: 100%;
            height: auto!important;
            margin-bottom: 10px;
        }
    }
    .img-upload{
        display: block;
    }
    
}</style>