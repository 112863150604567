import * as request from '@/request/common';
import storage from 'store'
import store from '@/store'
const playerJs = 'https://player.polyv.net/script/player.js';
const VideoInit = {
    /**
     * 
     * @param {选择器} el 
     * @param {视频vid} vid 
     * @param {视频宽度} width 
     * @param {视频高度} height 
     * @param {视频片头片尾广告} adMatter
     * @param {封面图} bgImg
     */
    init(el,vid,width,height,adMatter,bgImg){
        clearTimeout(VideoInit.playerTimer || 0)
        // 播放器初始状态
        VideoInit.videoStateEnded = false
        // 播放时长，单位m,用于判断赠送积分
        VideoInit.durationCount = 0
        width = width || '100%';
        let params = {el,vid,width,height,adMatter,bgImg}
        VideoInit.getVieoPlayParamsByVid(params);
    },
    // 获取视频信息
    getVieoPlayParamsByVid(params){
        request.get("GetVieoPlayParamsByVid",{vid:params.vid}).then(res => {
            let { data } = res;
            store.commit('updateState', { transcriptList: data.content });
            params.videoInfo = data;
            if(res.code == 200 && data){
                VideoInit.loadPlayerScript(VideoInit.loadPlayer,params);
            }
        })
    },
    loadPlayerScript(callback,params) {
        if (!window.polyvPlayer) {
            const myScript = document.createElement('script');
            myScript.setAttribute('src', playerJs);
            myScript.onload = () => {
                callback(params);
            }
            document.body.appendChild(myScript);
        } else {
            callback(params);
        }
    },

    loadPlayer(params) {
        let { videoInfo,width, height,el,vid,adMatter,bgImg } = params;
        const polyvPlayer = window.polyvPlayer;
        if (width == '100%' && !height) {
            width = (document.querySelector(el)||{}).clientWidth;
            height = width / 16 * 9;
        };
        // 参数文档
        // https://help.polyv.net/index.html#/vod/js/video_player/reference
        VideoInit.player = polyvPlayer({
            wrap: el,
            width,
            height,
            ban_seek_by_limit_time: 'off', //是否禁止拖拽至未播放的位置
            autoplay: false,
            // ban_seek:'off', //是否禁止拖拽进度条
            ban_ad_time:true, //是否隐藏广告倒计时
            speed: false, //倍速播放
            audioMode:false, //是否启用音频播放模式
            // 以下为需要传入的参数
            ts: videoInfo.ts_video,
            sign: videoInfo.sign,
            vid,
            df: 1,
            loading_bg_img:bgImg, //封面图
            cover_display: 'scaleAspectFit', //封面图显示方式
            adMatter:adMatter,  //广告配置
            // statistics: {
            //     param4: '67776225'
            // },
            viewerInfo: {
                viewerId: videoInfo.user_id,
                viewerName: videoInfo.real_name,
                viewerAvatar: ''
            },
            playsafe: (vid, next) => {
                request.get("GetToken",{vid,user_id:videoInfo.user_id,user_name:videoInfo.real_name}).then(res => {
                  next(res.data.token);
                })
            }
        });
        // 视频初次播放或由暂停恢复播放时触发
        VideoInit.player.on('s2j_onVideoPlay',()=>{
            VideoInit.videoStateEnded = false
            clearTimeout(VideoInit.playerTimer || 0)
            if((store.getters.userInfo||{}).Mobile){
                VideoInit.handleUpdateStudyLog(params)
            }
        })
        // 视频暂停时触发
        VideoInit.player.on('s2j_onVideoPause',()=>{
            VideoInit.videoStateEnded = true
            clearTimeout(VideoInit.playerTimer || 0)
        })
        // 视频播放完毕时触发
        VideoInit.player.on('s2j_onPlayOver',()=>{
            VideoInit.videoStateEnded = true
            clearTimeout(VideoInit.playerTimer || 0)
        })
    },
    // 更新学习时长
    handleUpdateStudyLog(params){
        let { videoInfo} = params;
        // 视频已结束或已暂停
        if (VideoInit.videoStateEnded){
            return false
        }
        VideoInit.playerTimer = setTimeout(()=>{
            // 每观看一分钟请求一次更新学习时长接口
            request.post('UpdateStudyLog',{
                obj_id: videoInfo.log_id,
                type: 4
            }).then(res=>{}).catch(err => {})
            clearTimeout(VideoInit.playerTimer || 0)
            VideoInit.handleUpdateStudyLog(params)
            // 累计播放时长，用于判断赠送积分
            VideoInit.durationCount += 1
            //获取视频直播观看多久加一次积分接口
            if(!storage.get('TimeRule')){
                request.get('GetPointTime').then(res=>{
                    storage.set('TimeRule',res)
                    if(VideoInit.durationCount == res.data.watch_video){
                        // 增加积分
                        request.post('AddPoint',{
                            task_id:6,
                            type_id: videoInfo.video_id
                        }).then(res => {}).catch(err => {})
                    }
                })
            }else{
                if(VideoInit.durationCount == storage.get('TimeRule').data.watch_video){
                    // 增加积分
                    request.post('AddPoint',{
                        task_id:6,
                        type_id: videoInfo.video_id
                    }).then(res => {}).catch(err => {})
                }
            }
        }, 60 * 1000)
    },
    destroy() {
        clearTimeout(VideoInit.playerTimer || 0)
        if (VideoInit.player) {
            VideoInit.player.destroy();
        }
    },
    // 获取当前播放时间
    getCurrentTime () {
        return VideoInit.player.j2s_getCurrentTime()
    },
    // 跳转到某个播放时间 单位s
    seekVideo (time) {
        if (time && VideoInit.player) {
            VideoInit.player.j2s_seekVideo(time)
        }
    }
}



export const init = VideoInit.init;
export const destroy = VideoInit.destroy;
export const getCurrentTime = VideoInit.getCurrentTime;
export const seekVideo = VideoInit.seekVideo;
