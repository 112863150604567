<template>
    <div class="filter-box">
        <div class="flex x-between fs14 mt5 pb10">
            <div class="mt5 mobile-left">
                <span class="pb15 strong">{{ title }}</span>
                <a href="javascript:;" @click="handleClickEvent(-1, -1)" :class="{ 'active': selectIndex == -1 }">不限</a>
            </div>
            <div class="flex-item flex flex-wrap x-left mobile-scroll">
                <div class="t-l" :class="['overflow-show',{ 'overflow-hidden': !is_open }]">
                    <a v-for="(item, index) in list" class="ml5 mt5" :class="{ 'active': index == selectIndex }"
                        :key="index" @click="handleClickEvent(index, item.id)" href="javascript:;">{{ item.name }}</a>
                </div>
                <template v-if="isShowMore">
                    <div v-if="!is_open" class="ml5 mt5 flex-item x-right unfold flex y-center" @click="handleUnfold">展开
                        <i class="med med-zhankai"></i></div>
                    <div v-else class="ml5 mt5 flex-item x-right unfold flex y-center"
                        @click="handleFold">收起 <i class="med med-zhankai shouqi"></i></div>
                </template>    
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: "filter-tab",
    props: {
        type:{
            type: String,
            default: '',
        },
        selected: {
            type: [Number, String],
            default: -1,
        },
        title: {
            type: String,
            default: '',
        },
        list: {
            type: Array,
            default: () => [
                // {id:1,name:'北京'},
                // {id:2,name:'天津'},
            ],
        },
        isShowMore: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            selectIndex: -1,
            listBackups: [],
            is_open: false,
        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
    watch: {
        selected: {
            handler(newVal) {
                this.selectIndex = newVal;
            },
            immediate: true,
        },
    },
    created() {
        // if(this.type != 'special'){
        //     this.list.forEach((item, index) => {
        //         // item.subject_ids 相当于判断是否是学科
        //         if (item.subject_ids && this.userInfo.operate_subject == item.id) {
        //             this.selectIndex = index
        //             //this.handleClickEvent(index, this.list[index].id)
        //         }
        //     })
        // }
        
    },
    methods: {
        handleClickEvent(index, id) {
            this.selectIndex = index;
            this.$emit('clickItem', index, id);
        },
        handleUnfold() {
            this.is_open = true
            this.$emit('unfoldCallBack')
        },
        handleFold() {
            this.is_open = false
            this.$emit('foldCallBack')

        }
    },
    mounted() {

    }
};
</script>

<style scoped lang="less">
.filter-box {
    &:not(:last-child) {
        border-bottom: 1px solid #e1e6f5;
    }

    span,
    a {
        color: @title;
        display: inline-block;
        padding: 3px 14px;
    }

    a:hover,
    a.active {
        background: @theme;
        border-radius: 13px;
        color: #fff;
    }
}

.unfold {
    cursor: pointer;
    color: #1e51c9;

    .shouqi {
        transform: rotate(180deg);
    }
}

.overflow-hidden {
    width: calc(100% - 60px);
    height: 34px;
    overflow: hidden;
}

@media screen and (max-width:768px) {
    .filter-box {
        .mobile-left{
            width: 125px;
        }
        .mobile-scroll {
            width: calc(100% - 125px);
            overflow: hidden;
            display: block;
            .overflow-show{
                // width: calc(100% - 15px);
                width:100%;
                overflow-x: scroll;
                white-space:nowrap;
            }
        }

        .unfold {
            display: none;
        }
    }

}
</style>
