<template>
    <div class="box">
        <company-info :navLists="navList" :navCurrentIds="navCurrentId"></company-info>
        <div class="homepage-content flex">
            <div class="homepage-list flex-item">
                <a-spin :spinning="loading" tip="Loading...">
                    <template v-if="productList.length">
                        <course-item :targetType="1" width="230px" height="130px" :data-list="productList" @clickEvent="handleProductClick($event)"></course-item>
                    </template>
                    <a-empty v-if="!productList.length && !loading" />
                    
                </a-spin>
                <div class="t-c mt30 info-pagination" v-if="productCount > 0">
                    <a-pagination :total="productCount" v-model:current="params.page" :page-size="params.limit"
                        show-quick-jumper @change="handlePageChangeEvent" :hideOnSinglePage="true" />
                </div>
            </div>
            <div class="homepage-right t-l">
                <layout-right :config="layoutRightConfig"></layout-right>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import { companyNavList } from "@/config/const";
import companyInfo from '@/components/company-info.vue';
import Header from "@/components/layout/layout-header";
import Footer from "@/components/layout/layout-footer";
import layoutRight from '@/components/layout/layout-right';
import courseItem from '@/components/courser-list-item';

export default {
    name: "CompanyProduct",
    components: {
        Header, Footer, layoutRight, courseItem,companyInfo
    },
    data() {
        return {
            navList: companyNavList(this.$route.query.company_id),//顶部菜单
            navCurrentId: 2,//当前页面菜单对应下标
            params:{
                page: 1,
				limit: 12,
				committee_id:this.$route.query.company_id,
            },
            productCount:0,
            productList:[],
            loading: true,
            layoutRightConfig: {
                meeting: { //会议
                    show: true, // 是否显示
                    title: '会议', // 标题
                    methods: 'get',
                    moreLink:'/company/meeting?id=' + this.$route.query.company_id,
                    data: {
                        page: 1,
                        pageSize: 2,
                        committee_id: this.$route.query.company_id
                    },   // 参数条件
                },
                live: { //直播
                    show: true, // 是否显示
                    methods: 'get',
                    moreLink:'/company/live?id=' + this.$route.query.company_id,
                    title: '直播', // 标题
                    data: {
                        page: 1,
                        limit: 2,
                        committee_id: this.$route.query.company_id,
                        no_end_time: 1
                    },   // 参数条件
                },
                orgMeetback: {
                    show: true, // 是否显示
                    title: '视频', // 标题
                    methods: 'get',
                    moreLink:'/company/video?id=' + this.$route.query.company_id,
                    data: {
                        page: 1,
                        limit: 3,
                        committee_id_search: this.$route.query.company_id,
                    },   // 参数条件
                },
            },
        }
    },
    created() {
        this.getProductList()
    },
    methods: {
        // 资讯列表
        getProductList(){
            this.loading = true

            this.request.post('CommitteeProductType',this.params).then(res => {
                // 资讯
                this.productList = res.data.list
                this.productCount = parseInt(res.data.count)

                this.loading = false
            })
        },
        
        // 切换页码
        handlePageChangeEvent(page) {
            window.scrollTo(0,0);
            this.params.page = page
            this.getProductList()
        },
        // 点击产品类别
        handleProductClick(e){
            this.$router.push({ 
                path: '/company/product-list',
                query: {
                    company_id:this.$route.query.company_id,
                    class_id:e.id,
                    title:e.title
                }
            });
        }
    }
}
</script>

<style scoped lang="less">

.homepage-header {
    width: 1200px;
    margin: 0 auto;

    &-title {
        max-width: 200px;
    }

    &-img {
        width: 88px;
        height: 88px;
        background: red;
    }
}
.homepage-content {
    width: 1240px;
    margin: 0 auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
}
.homepage-list {
    margin-right: 30px;
    /deep/.component-box .course-block .course-item .course-info{
        height: 70px;
    }
}
.homepage-right {
    width:400px;
}
@media screen and (max-width:768px){
    .homepage-content{
        display: block;
        padding: 10px;
        width: 100%;
        .homepage-list{
            display: block;
            margin-right: 0;
            
            .info-pagination{
                margin-top: 10px;
            }
        }
        .homepage-right{
            width: 100%;
            margin-top: 50px;
        }
    }
}
</style>