<template>
    <div class="box">
        <company-info :navLists="navList" :navCurrentIds="navCurrentId"></company-info>
        <div class="homepage-content flex">
            <div class="homepage-list flex-item">
                <a-spin :spinning="loading" tip="Loading...">
                    <div class="interview-list" v-if="recruitList.length">
                        <div v-for="(item,index) in recruitList" :key="index" class="hot-topic-box" :class="{'mt20': index != 0}">
                            <hot-topic-item containerWidth="750px" width="220px" :item="item" class="bb" :showCollect="false" :path="'/company/news-detail?company_id='+$route.query.company_id+'&pageType=zhaopin'" :type="item.type">
                            </hot-topic-item>
                        </div>
                    </div>
                    <a-empty v-if="!recruitList.length && !loading" />
                </a-spin>
                <div class="t-c mt30 info-pagination" v-if="recruitCount > 0">
                    <a-pagination :total="recruitCount" v-model:current="params.page" :page-size="params.limit"
                        show-quick-jumper @change="handlePageChangeEvent" :hideOnSinglePage="true" />
                </div>
            </div>
            <div class="homepage-right t-l">
                <layout-right :config="layoutRightConfig"></layout-right>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import { companyNavList } from "@/config/const";
import companyInfo from '@/components/company-info.vue';
import Header from "@/components/layout/layout-header";
import Footer from "@/components/layout/layout-footer";
import layoutRight from '@/components/layout/layout-right';
import hotTopicItem from '@/components/hot-topic-item.vue';
import { jsonFormat } from '@/utils/jsonFormat';

export default {
    name: "index",
    components: {
        Header, Footer, layoutRight, hotTopicItem,companyInfo
    },
    data() {
        return {
            navList: companyNavList(this.$route.query.company_id),//顶部菜单
            navCurrentId: 6,//当前页面菜单对应下标
            params:{
                committee_id: this.$route.query.company_id,
                limit: 10,
                page: 1,
                title: "",
            },
            recruitCount:0,
            recruitList:[],
            loading: true,
            layoutRightConfig: {
                meeting: { //会议
                    show: true, // 是否显示
                    title: '会议', // 标题
                    methods: 'get',
                    moreLink:'/company/meeting?company_id=' + this.$route.query.company_id,
                    data: {
                        page: 1,
                        pageSize: 2,
                        committee_id: this.$route.query.company_id
                    },   // 参数条件
                },
                live: { //直播
                    show: true, // 是否显示
                    methods: 'get',
                    moreLink:'/company/live?company_id=' + this.$route.query.company_id,
                    title: '直播', // 标题
                    data: {
                        page: 1,
                        limit: 2,
                        committee_id: this.$route.query.company_id,
                        no_end_time: 1
                    },   // 参数条件
                },
                orgMeetback: {
                    show: true, // 是否显示
                    title: '视频', // 标题
                    methods: 'get',
                    moreLink:'/company/video?company_id=' + this.$route.query.company_id,
                    data: {
                        page: 1,
                        limit: 3,
                        committee_id_search: this.$route.query.company_id,
                    },   // 参数条件
                },
            },
        }
    },
    created() {
        this.getRecruitList()
    },
    methods: {
        // 招聘列表
        getRecruitList(){
            this.loading = true

            this.request.post('CommitteeRecruit',this.params).then(res => {
                res.data.list.forEach(item=>{
                    if(item.images && item.images.length > 0){
                        item.infomation_type = '2'
                    }
                })
                let obj = {
                    news_title: 'title',
                    browsing:'hits',
                }
                this.recruitList = jsonFormat(res.data.list, obj)
                this.recruitCount = parseInt(res.data.count)

                this.loading = false
            })
        },
        
        // 切换页码
        handlePageChangeEvent(page) {
            window.scrollTo(0,0);
            this.params.page = page
            this.getRecruitList()
        },
    }
}
</script>

<style scoped lang="less">

.homepage-header {
    width: 1200px;
    margin: 0 auto;

    &-title {
        max-width: 200px;
    }

    &-img {
        width: 88px;
        height: 88px;
        background: red;
    }
}
.homepage-content {
    width: 1240px;
    margin: 0 auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
}
.homepage-list {
    margin-right: 30px;
    .interview-list{
        min-height: 500px;
    }
    .bb {
        border-bottom: 1px solid #D8D8D8;
    }

    .hot-topic-box:last-child .bb {
        border-bottom: 0;
    }
}
.homepage-right {
    width:400px;
}
@media screen and (max-width:768px){
    .homepage-content{
        display: block;
        padding: 10px;
        width: 100%;
        border-radius: 0;
        .homepage-list{
            display: block;
            margin-right: 0;
            
            .interview-list{
                min-height: 0;
                .hot-topic-box{
                    margin-top: 0;
                    .bb {
                        padding: 10px 0;
                        border-bottom: 1px solid #D8D8D8;
                    }
                    &:last-child{
                        .bb{
                            border-bottom: 0;
                        }
                    }
                }
            }
            .info-pagination{
                margin-top: 10px;
            }
        }
        .homepage-right{
            width: 100%;
            margin-top: 50px;
        }
    }
}
</style>