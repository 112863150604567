<template>
    <div class="mobile flex flex-column">
        <img class="mobile-logo" src="~@/assets/images/mobile-logo.png" alt="">
        <div class="mobile-text">
            <p class="mobile-fontsize color000 strong">手机观看请前往</p>
            <p class="mobile-fontsize color000 strong"><span class="b_color">会务通参会助手</span>小程序</p>
        </div>
        <img class="mobile-pic" src="~@/assets/images/mobile-pic.png" alt="">
        <div v-if="isWeixin" class="mobile-wx">
            <wx-open-launch-weapp id="launch-btn" username="gh_6f50eb1bbbe2" path="/pages/index/index">
                <script type="text/wxtag-template">
                    <style>
                        button:focus{outline:0}
                    </style>
                    <button style="background: transparent;border: 0;height: 42px;color:#fff;" class="t-c colorfff">立即前往小程序</button>
                </script>
            </wx-open-launch-weapp>
        </div>
        <div v-if="isMobile && !isWeixin">
            <button class="mobile-wx t-c colorfff" @click="openWeapp">立即前往小程序</button>
        </div>
    </div>
</template>

<script>
import wx from "weixin-js-sdk";
import axios from 'axios';
export default {
    name: "openInMobile",
    components: {

    },
    data() {
        return {
            isWeixin: false,
            isWXWork: false,
            isMobile: false,
            c: '',
        }
    },
    created() {
        this.initVConsole()
        let ua = navigator.userAgent.toLowerCase()
        this.isWXWork = ua.match(/wxwork/i) == 'wxwork'
        this.isWeixin = !this.isWXWork && ua.match(/micromessenger/i) == 'micromessenger'
        if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i)) {
            this.isMobile = true
        }
        // console.log(this.isMobile, this.isWXWork, this.isWeixin)
        if (this.isMobile) {
            if (this.isWeixin) {
                let url = window.location.href.split('#')[0]
                // console.log(url)
                delete axios.defaults.headers['key'];
                delete axios.defaults.headers['committeeID'];
                delete axios.defaults.headers['source'];
                // alert(url)
                // alert('进来了这里')
                let data = {
                    url: url,
                    appId: 'wx7b2de810491c0569'
                }
                setTimeout(() => {
                    axios({
                        url: "https://mm.sciconf.cn/ajax/get-weixin-jsapi",
                        method: 'post',
                        data: data
                    }).then(res => {
                        wx.config({
                            // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            appId: res.appId, // 必填，公众号的唯一标识
                            timestamp: res.timestamp, // 必填，生成签名的时间戳
                            nonceStr: res.nonceStr, // 必填，生成签名的随机串
                            signature: res.signature,// 必填，签名，见附录1
                            openTagList: ['wx-open-launch-weapp'], // 填入打开小程序的开放标签名
                            jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage', 'onMenuShareQQ', 'onMenuShareWeibo', 'chooseImage', 'previewImage', 'uploadImage', 'downloadImage', 'getLocalImgData',] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                        });
                        wx.ready(function (res) {
                            console.log(res)
                            // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
                        });
                        // 通过error接口处理失败验证
                        wx.error(function (err) {
                            console.log(err)
                            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
                        });
                    }).catch(err => {
                        // alert(err)
                    })
                },1000)
            } else {
                var c = new cloud.Cloud({
                    // 必填，表示是未登录模式
                    identityless: true,
                    // 资源方 AppID
                    resourceAppid: 'wxb194ea9d8c29caa5', // <!-- replace -->
                    // 资源方环境 ID
                    resourceEnv: 'medcon-5g2mbp7d75631541', // <!-- replace -->
                })
                c.init()
                this.c = c
                // console.log(this.c)
            }
        }
    },
    methods: {
        async openWeapp() {
            var c = this.c
            console.log(c);
            const res = await c.callFunction({
                name: 'public',
                data: {
                    action: 'getUrlScheme',
                    path: '/pages/index/index',
                    query: '',
                },
            })
            window.location.href = res.result.openlink
        },
        initVConsole() {
            const oScript = document.createElement('script');
            oScript.type = 'text/javascript';
            oScript.src = 'https://cdn.bootcss.com/vConsole/3.3.0/vconsole.min.js';
            oScript.onload = this.initObj;
            document.body.appendChild(oScript);
        },
        initObj() {
            const vConsole = new VConsole();
            console.log('vconsole');
        }
    }
}
</script>

<style scoped src="@/assets/css/weui.min.css">

</style>
<style lang="less">
#__vconsole {
    display: none;
    &.show{
        display: block;
    }
}
</style>
<style lang="less" scoped>
.mobile {
    width: 100%;
    min-height: 100vh;
    background: #D9EAFF;
    font-size: 思源宋体 CN;
    position: relative;

    &-logo {
        width: 12.8125rem;
        height: 2.1875rem;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 5.625rem
            /* 90/16 */
        ;

    }

    &-text {
        position: absolute;
        left: 0;
        right: 0;
        top: 11.875rem
            /* 190/16 */
        ;
    }

    &-fontsize {
        font-size: .9375rem
            /* 15/16 */
        ;
        letter-spacing: .0625rem
            /* 1/16 */
        ;
    }

    .b_color {
        color: #0D50A3;
    }

    &-pic {
        width: 10.1875rem
            /* 163/16 */
        ;
        height: 10.625rem
            /* 170/16 */
        ;
        position: absolute;
        margin: auto;
        top: 17.5rem
            /* 280/16 */
        ;
        left: 0;
        right: 0;
    }

    &-wx {
        width: 9.5rem
            /* 152/16 */
        ;
        height: 2.625rem
            /* 42/16 */
        ;
        border: 0;
        // background: to right, #5F96E6, #1E51C9;
        background: linear-gradient(to right, #5F96E6, #1E51C9);
        border-radius: 1.25rem
            /* 20/16 */
        ;
        position: absolute;
        margin: auto;
        top: 32.875rem
            /* 526/16 */
        ;
        left: 0;
        right: 0;
        font-size: .875rem
            /* 14/16 */
        ;
        letter-spacing: .0625rem
            /* 1/16 */
        ;
    }
}
</style>