<template>
    <div class="box">
        <company-info :navLists="navList" :navCurrentIds="navCurrentId"></company-info>
        <div class="homepage-content flex">
            <div class="homepage-list flex-item">
                <a-spin :spinning="loading" tip="Loading...">
                    <div class="interview-list" v-if="liveList.length">
                        <!-- 直播列表模块 -->
                        <div class="flex flex-wrap x-left live-item-box">
                            <template v-for="(item,index) in liveList">
                                <live-item @click="liveJumpDetail(item.live_id)" :key="index" class="mb30" width="230px" :item="item"></live-item>
                            </template>
                        </div>
                    </div>
                    <a-empty style="margin-top:100px;" v-if="!liveList.length && !loading" />
                </a-spin>
                <div class="t-c mt30 live-pagination" v-if="liveTotal > 0">
                    <a-pagination :total="liveTotal" v-model:current="liveParams.page" :page-size="liveParams.limit"
                        show-quick-jumper @change="handlePageChangeEvent" :hideOnSinglePage="true" />
                </div>
            </div>
            <div class="homepage-right t-l">
                <layout-right :config="layoutRightConfig"></layout-right>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import { companyNavList } from "@/config/const";
import companyInfo from '@/components/company-info.vue';
import Header from "@/components/layout/layout-header";
import Footer from "@/components/layout/layout-footer";
import layoutRight from '@/components/layout/layout-right';
import hotTopicItem from '@/components/hot-topic-item.vue';
import liveItem from '@/components/live-item.vue';
import { jsonFormat } from '@/utils/jsonFormat';
import { liveJumpDetail } from '@/utils/jumpPageMethods';
import storage from 'store'

export default {
    name: "index",
    components: {
        companyInfo, Header, Footer, layoutRight, hotTopicItem, liveItem
    },
    data() {
        return {
            navList: companyNavList(this.$route.query.company_id),//顶部菜单
            navCurrentId: 4,//当前页面菜单对应下标
            liveParams:{
                page: 1,
                limit: 12,
                committee_id: this.$route.query.company_id,
                no_end_time: 1,
            },
            liveTotal:0,
            liveList:[],
            infoCount:0,
            loading: true,
            layoutRightConfig: {
                news: {
                    show: true, // 是否显示
                    methods: 'post',
                    title: '资讯', // 标题
                    moreLink: '/company/news?company_id=' + this.$route.query.company_id,
                    detailLink: '/company/news-detail?company_id='+this.$route.query.company_id+'&pageType=news',
                    data: {
                        page: 1,
                        limit: 5,
                        committee_id:this.$route.query.company_id,
                        type : 5,//必传
                        is_verify : 1,//必传 https://docs.qq.com/doc/DY2lGaXdVelRrQnJP
                    },   // 参数条件
                },
                meeting: { //会议
                    show: true, // 是否显示
                    title: '会议', // 标题
                    methods: 'get',
                    moreLink:'/company/meeting?company_id=' + this.$route.query.company_id,
                    data: {
                        page: 1,
                        pageSize: 2,
                        committee_id: this.$route.query.company_id
                    },   // 参数条件
                },
                orgMeetback: {
                    show: true, // 是否显示
                    title: '视频', // 标题
                    methods: 'get',
                    moreLink:'/company/video?company_id=' + this.$route.query.company_id,
                    data: {
                        page: 1,
                        limit: 3,
                        committee_id_search: this.$route.query.company_id,
                    },   // 参数条件
                },
            },
        }
    },
    created() {
        this.pageInit()
    },
    methods: {
        liveJumpDetail,
        getLiveList(){
            this.request.get('GetLiveList',this.liveParams).then(res=>{
                let liveObj = {
                    start_date:'start_time',
                    end_date:'end_time',
                    img:'live_banner'
                }
                this.liveTotal = Number(res.data.count)
                this.liveList = jsonFormat(res.data.list,liveObj)
            })
        },
        pageInit() {
            this.loading = true
            let reqArr = [
                this.request.get('GetLiveList',this.liveParams)
            ]
            Promise.all(reqArr).then(res => {
                // 机构号直播列表
                let liveObj = {
                    start_date:'start_time',
                    end_date:'end_time',
                    img:'live_banner'
                }
                if(res[0].data.list.length){
                    this.liveTotal = parseInt(res[0].data.count)
                    this.liveList = jsonFormat(res[0].data.list,liveObj)
                }
                this.loading = false
            })
        },
        // 切换页码
        handlePageChangeEvent(page) {
            window.scrollTo(0,0);
            this.liveParams.page = page
            this.getLiveList()
        },
    }
}
</script>

<style scoped lang="less">
.background {
    width: 100%;
    height: 408px;
    background: linear-gradient(360deg, rgba(234, 249, 255, 0) 0%, #EAFAFF 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.homepage-header {
    width: 1200px;
    margin: 0 auto;

    &-title {
        max-width: 200px;
    }

    &-img {
        width: 88px;
        height: 88px;
        background: red;
    }
}
.homepage-content {
    width: 1240px;
    margin: 0 auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
}
.homepage-list {
    margin-right: 30px;
    min-height: 500px;
    .bb {
        border-bottom: 1px solid #D8D8D8;
    }

    .hot-topic-box:last-child .bb {
        border-bottom: 0;
    }
    .live-item {
        margin-right: 30px;
        &:nth-child(3n) {
            margin-right: 0;
        }
    }
}
.homepage-right {
    width:400px;
}
@media screen and (max-width:768px){
    .homepage-content{
        display: block;
        padding: 10px;
        width: 100%;
        .homepage-list{
            display: block;
            margin-right: 0;
            min-height: 0;
            .interview-list{
                
                .live-item-box {
                    justify-content: space-between;
                }
                .live-item {
                    margin-right: 0;
                    margin-bottom: 15px;
                }
            }
            .live-pagination{
                margin-top: 10px;
            }
            /deep/.ant-empty{
                margin-top: 0!important;
            }
        }
        .homepage-right{
            width: 100%;
            margin-top: 50px;
        }
    }
    
}
</style>