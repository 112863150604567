<template>
    <div class="box">
        <div class="background"></div>
        <Header :navList="navList" @search="handleSearchEvent" :navCurrentId="navCurrentId" :search-style="{width:150}" :typeList="typeList" :logo="logoUrl">
        </Header>
        <div class="hospital-msg mb20 t-l flex x-left flex-wrap y-center fs16">
            <i class="med med-xiaolaba fs20 mr10"></i>
            已入驻 {{ companyCount }}+ 企业！ 点击 <span class="strong" style="color:#1E51C9;cursor:pointer;" @click="handleEnterCompany">【入驻申请】</span> 快速入住医企秀。
        </div>
        <a-skeleton active class="content" :loading="loading">
            <div class="content">
                <div class="flex flex-wrap x-left y-top" v-if="companyList.length">
                    <router-link :to="'/company/index?company_id='+item.id" class="item flex x-left color000" v-for="(item,index) in companyList" :key="index">
                        <div class="top-img">
                            <img :src="item.index_logo | urlFilter(250,'avatar') || require('@/assets/images/committee-logo.jpg')" alt="">
                        </div>
                        <div class="info ml20 flex-item x-between">
                            <div class="color000 strong fs20 t-l row-1">{{ item.name }}</div>
                            <div v-if="item.desc" class="row-4 t-l mt10">{{ item.desc }}</div>
                            <div v-else class="t-l mt10">暂无简介</div>
                        </div>
                    </router-link>
                </div>
                <a-empty style="margin-top:100px;" v-if="!companyList.length && !loading" />
            </div>
        </a-skeleton>
        <Footer></Footer>
        <enter-company v-if="visible" :visible="visible"></enter-company>
    </div>
    
</template>

<script>
    import {organizationList, organizationLogo} from "@/config/const";
    import Header from "@/components/layout/layout-header";
    import Footer from "@/components/layout/layout-footer";
    import EnterCompany from './components/enterCompany.vue'
    import storage from 'store'
    export default {
        name: "HospitalList",
        components : {
            Header, Footer,EnterCompany
        },
        data(){
            return {
                visible:false,
                logoUrl : organizationLogo,
                navList : organizationList,//顶部菜单
                navCurrentId : 3,//当前页面菜单对应下标
                pageSizeOptions : ['12' , '16' , '22' , '28'],
                paginationParams : {
                    page : 1, //页数
                    pageSize : 12, //当前页展示多少
                    total : 0, // 数据总量
                },
                typeList : [],
                type:'',//类型id
                companyList:[],
                companyCount: 0, //入住数量
                allDataList : [], //全部数据
                dataList : [],//单页数据
                loading : true,
            }
        },
        created() {
            this.getCompanyList()
        },
        computed:{
          numberFormat(){
              return function (number) {
                  if(!number) return 0;
                  number =number + '';
                  if(number.length >= 5){
                      let offset = number[number.length-3] >= 5 ? 1 : 0;
                      return (parseFloat(number.slice(0,number.length-3)) + offset) / 10 + 'W';
                  }else{
                      return number;
                  }
              }
          }
        },
        methods :{
            // 企业号列表
            getCompanyList(){
                this.loading = true;

                this.request.post('CommitteeList',{type:'30',subject:''}).then(res => {
                    this.companyCount = res.data.length || 0
                    res.data.forEach( item => {
                        if(item.desc){
                            item.desc = this.escape2Html(item.desc);
                            item.desc = this.removeHTMLTag(item.desc);
                            item.desc = item.desc.replace(/[\s\r\n]/g,'');
                        }
                    });
                    this.companyList = res.data
                    
                    this.loading = false;
                })
            },
            // 转意符换成普通字符
            escape2Html(str) { 
                var arrEntities={'lt':'<','gt':'>','nbsp':' ','amp':'&','quot':'"'}; 
                return str.replace(/&(lt|gt|nbsp|amp|quot);/ig,function(all,t){return arrEntities[t];}); 
            } ,
            //  移除HTML标签代码
            removeHTMLTag(str) {
                str = str.replace(/<\/?[^>]*>/g,''); //去除HTML tag
                str = str.replace(/[ | ]*\n/g,'\n'); //去除行尾空白
                //str = str.replace(/\n[\s| | ]*\r/g,'\n'); //去除多余空行
                str=str.replace(/ /ig,'');//去掉 
                return str;
            },
            handleSearchEvent(e){
                this.queryStr = e;
                this.getCompanyList()
            },
            // 企业入驻
            handleEnterCompany(){
                this.visible = true
            },
        }
    }
</script>

<style scoped lang="less">
    .background{
        width: 100%;
        height: 408px;
        background: linear-gradient(360deg, rgba(234,249,255,0) 0%, #EAFAFF 100%);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    .hospital-msg{
        margin: 20px auto 0;
        width: 1240px;
        background: #ffffff;
        padding: 15px 10px;
        border-radius: 10px;
        box-sizing: border-box;
    }
    .content{
        width: 1240px;
        min-height: 798px;
        background: #FFFFFF;
        border-radius: 10px;
        padding: 20px;
        margin: 20px auto 0;
        align-content: flex-start;
        .item{
            width: calc((100% - 20px) / 2);
            height: 150px;
            border-radius: 10px;
            border: 1px solid #EFF4FF;
            margin-right: 20px;
            margin-bottom: 20px;
            transition: .3s;
            padding: 0 15px;
            box-sizing: border-box;
            &:nth-child(2n+2){
                margin-right: 0;
            }
            .top-img{
                position: relative;
                width: 84px;
                img{
                    position: absolute;
                    width: 84px;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                }
            }
            .info{
                transition: all .3s;
                border-radius: 0 0px 10px 10px;
                padding: 10px 0;
                box-sizing: border-box;
            }
            &:hover{
                border-color: rgba(0,0,0,.09);
                box-shadow: 0 2px 8px rgba(0,0,0,.09);
            }
        }
    }
    .search{
        .ant-input-wrapper{
            border-radius:40px;
            border: 1px solid #d9d9d9;
            overflow: hidden;
        }
        .ant-btn-primary{
            background: @theme;
            width:60px;
            border-radius:40px!important;
        }
        .ant-input{
            border:0;
            outline: none;
            padding-left:20px;
            border-radius:40px;
            &:focus{
                box-shadow: none;
            }
        }
        .anticon-search{
            font-size:22px;
            height: 18px;
            overflow: hidden;
        }

        /deep/ .ant-input-group-addon{
            border: none;
            background: #FFFFFF;
        }
    }
    
    @media screen and (max-width:768px){
        .hospital-msg{
            width: 100%;
            font-size: 12px;
            margin-bottom: 15px;
            padding: 10px 10px;
            border-radius: 0;
            margin-top: 0;
        }
        .content{
            width: 100%;
            margin: 0;
            padding: 10px;
            border-radius: 0;
            .item{
                width: 100%;
                margin-right: 0!important;
            }
        }
    }
</style>