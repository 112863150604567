import { committee_id, front_url,mini_url } from '@/config';
import { getUrlParams } from '@/utils/common'; 
export const urlReplace  = (news_content,callback)=>{
    if (news_content) {
        let origin = window.location.origin;

        // 处理视频 /source src="[^"]*"/g
        var array_of_video_matches = news_content.match(/<video\b[^>]+[^>]*>([\s\S]*?)<\/video>/g) || [];
        array_of_video_matches.forEach((item,index) => {
            let vid = item.match(/src="([^"]*")/).pop();
            vid = vid.replace(/\"/g, '')
            news_content = news_content.replace(item,'<div id="player'+index+'"></div>');
            callback && typeof(callback) == 'function' && callback(vid,'#player'+index);
        })

        var array_of_matches = news_content.match(/href="([^"]*")/g) || [];
        // console.log(array_of_matches);
        
        array_of_matches.forEach(item => {
            item = item.replace('href=', '');
            let params = getUrlParams(item.replace(/amp;/g, ''));
            // 专家详情小程序路由替换成H5路由
            if (item.indexOf('/other-pages/expert-detail/expert-detail?uid') != -1) {
                news_content = news_content.replace(/\/other-pages\/expert-detail\/expert-detail\?uid\=/g, mini_url + 'cn/person-detail/' + committee_id + '?user_id=')

                // 替换微网站
            } else if (item.indexOf('/website-pages/index/index?id=') != -1) {
                news_content = news_content.replace(/\/website-pages\/index\/index\?id\=/g, front_url + '/cn/web/index/')

                // 替换新闻详情的路由
            } else if (item.indexOf('/other-pages/look-at-info-detail/look-at-info-detail') != -1) {
                news_content = news_content.replace(/\/other-pages\/look-at-info-detail\/look-at-info-detail/g, origin + '/#/infomation-detail')

                // 替换讲课视频的路由
            } else if (item.indexOf('/video-pages/video-play/video-play') != -1) {
                // news_content = news_content.replace(item, mini_url + 'online/video/play/' + committee_id + '?vid=' + params.id)
                news_content = news_content.replace(item,origin + '/#/video-list-detail?id=' + params.id)

                // 替换大会日程的路由
            } else if (item.indexOf('/website-pages/program/program?id=') != -1){
                news_content = news_content.replace(/\/website-pages\/program\/program\?id\=/g, front_url + '/cn/web/program/')

                // 替换专题详情的路由
            } else if (item.indexOf('/pages/topic-detail/topic-detail') != -1){
                news_content = news_content.replace(item, origin + '/#/special-detail?type=' + params.type + '&type_id=' + params.id)

                // 继教学分证书下载
            } else if (item.indexOf('/website-pages/meeting-certificate/meeting-certificate') != -1){
                news_content = news_content.replace(item, origin + '/#/meeting-certificate?meeting_id=' + params.meetingid + '&project_no=' + params.certProjectNo + '&search_year=' + params.certYear)
            }
        });

        // 所有链接改成新窗口打开
        news_content = news_content.replace(/href/g, 'target="_blank" href');
    }
    return news_content
}