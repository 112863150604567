<template>
    <div class="">
        <a-modal title="入驻医企秀" class="enter-company" :visible="visibles" @cancel="handleCancelEvent">
            <img class="bg-img" src="https://files.sciconf.cn/medcon/2021/07/20210730/2021073011175374921016853.png" />
            <div class="content">
                <img class="top-img-1" :src="'mpweixin/47712dc7035714b493cc2c3339dc48f.png' | urlFilter(750)" />
                <img class="top-img-2 mt10" :src="'mpweixin/eccf250942c0e1b1c7a0748e1cbdc26.png' | urlFilter(750)" />
                <div class="list-box">
                    <div class="list-item" v-for="(item,index) in listData" :key="index">
                        <div class="list-item-img">
                            <img src="https://files.sciconf.cn/medcon/2021/07/20210730/2021073011413816495382107.png" alt="">
                        </div>
                        <div class="title fs18 strong">{{ item.title }}</div>
                        <div class="title fs16 mt20">{{ item.detail }}</div>
                    </div>
                </div>
            </div>
            <template slot="footer">
                <router-link to="/company/enter" class="enter-btn t-c strong fs18 colorfff">马上入驻</router-link>
            </template>
            
        </a-modal>
    </div>
</template>

<script>
export default {
    name: 'EnterCompany',
    props: {
        visible:{
            type:Boolean,
            default:()=> false
        }
    },
    data(){
        return {
            visibles:false,
            listData:[
                {detail: "提供企业专属的网站、微网站和小程序专属主页，实现平台用户的引流拉新，整合用户数据，构建企业自己的私域流量池，实现对客户的合规可视化管理。",title: "企业专属主页"},
                {detail: "提供会议活动注册签到、市场调研、视频会议、网络直播、视频点播、在线教育等工具，实现学术会议活动的一站式全程合规管理，构建企业自己的在线会议活动及教育平台。",title: "会议活动全流程管理"},
                {detail: "拥有自主知识产权的视频会议客户端及网络直播平台，一键开启专家讲座、在线视频会议并轻松实现网络直播和会后视频点播回放。",title: "一键创建视频会及直播"},
                {detail: "面向300万医生用户，可按学科、地区分类，通过短信，微信，邮件、H5、小程序以及网站、微网站等方式，对目标用户进行精准的数字化营销推广。",title: "精准数字化营销推广"},
                {detail: "依托每年超5000场的线下会议，可根据学科为您推送相关领域的学术会议最新通知，可一键登录官网会议网站进行参展申请、添加团队代表、管理卫星会日程等会务操作，轻松实现线下代表展台扫码签到、卫星会直播转播，为您参与线下会议推广提供完善的配套服务。",title: "线下会会议配合服务"}
            ]
        }
    },
    components: {

    },
    computed: {
        
    },
    
    created(){
        this.visibles = this.visible
    },
    methods: {
        // 关闭弹窗
        handleCancelEvent() {
            this.visibles = false
        },
    },
    mounted() {

    },
}
</script>
<style lang="less" scoped>
.enter-company{
    overflow: auto;
    /deep/.ant-modal-body{
        height: 600px;
        max-height: 600px;
        padding-top: 0;
        overflow: auto;
        position: relative;
        .bg-img{
            width: 100%;
            height: auto;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
        .content{
            position: relative;
            z-index: 999;
            .top-img-1,.top-img-2{
                width: 100%;
            }
            .list-box{
                width: 100%;
                padding: 0 10px;
                box-sizing: border-box;
                .list-item{
                    width: 100%;
                    background: rgba(255,255,255,.3);
                    border: 1px solid #fff;
                    box-shadow: 0px 10px 8px 0px rgba(121,128,194,.06);
                    border-radius: 10px;
                    position: relative;
                    padding: 27px 25px;
                    color: #282c42;
                    box-sizing: border-box;
                    margin-bottom: 20px;
                    &-img{
                        width: calc(100% - 50px);
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: -32px;
                        margin: auto;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
    /deep/.ant-modal-footer{
        height: 60px;
        border: 0;
        .enter-btn{
            position: absolute;
            bottom: 10px;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 999;
            width: 300px;
            height: 40px;
            border-radius: 30px;
            background: #0087ff;
            line-height: 40px;
            cursor: pointer;
        }
    }
}
@media screen and (max-width:550px){
    .enter-company{
        width: 100%;
        /deep/.ant-modal{
            max-width: 100%;
            height: 80vh;
            margin: 0 auto;
            top: 20vh;
            overflow: hidden;
            .ant-modal-body{
                padding-bottom: 150px;
            }
            .ant-modal-footer{
                .enter-btn{
                    bottom: 190px;
                }
            }
        }
    }
}
</style>
