<template>
    <div class="box">
        <company-info @success="handleCompanySuccess" :navLists="navList" :navCurrentIds="navCurrentId"></company-info>
        <div class="homepage-content flex">
            <div class="homepage-list flex-item">
                <a-spin :spinning="loading" tip="Loading...">
                    <div class="homepage-left">
                        <!-- 资讯 -->
                        <div class="mt15 news-list" v-if="newsList.length">
                            <common-title :path="'/company/news?company_id='+$route.query.company_id" title="资讯"></common-title>
                            <div v-for="(item,index) in newsList" :key="index" class="hot-topic-box" :class="{'mt20': index != 0}">
                                <hot-topic-item containerWidth="750px" width="220px" :item="item" class="bb" :showCollect="false" :path="'/company/news-detail?company_id='+$route.query.company_id+'&pageType=news'" :type="item.type">
                                </hot-topic-item>
                            </div>
                        </div>
                        <!-- 产品 -->
                        <div class="mt15 news-list" v-if="productList.length">
                            <common-title :path="'/company/product?company_id='+$route.query.company_id" title="产品"></common-title>
                            <course-item width="230px" height="130px" :targetType="1" :data-list="productList" @clickEvent="handleProductClick($event)"></course-item>
                        </div>
                        <!-- 招聘 -->
                        <div class="mt15 news-list" v-if="zhaopinList.length">
                            <common-title :path="'/company/invite?company_id='+$route.query.company_id" title="招聘"></common-title>
                            <div v-for="(item,index) in zhaopinList" :key="index" class="hot-topic-box" :class="{'mt20': index != 0}">
                                <hot-topic-item containerWidth="750px" width="220px" :item="item" class="bb" :showCollect="false" :path="'/company/news-detail?company_id='+$route.query.company_id+'&pageType=zhaopin'" :type="item.type">
                                </hot-topic-item>
                            </div>
                        </div>
                        <a-empty v-if="!loading && !newsList.length && !productList.length && !zhaopinList.length"></a-empty>
                    </div>
                </a-spin>
            </div>
            <div class="homepage-right t-l mt15">
                <layout-right :config="layoutRightConfig"></layout-right>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import { companyNavList } from "@/config/const";
import Header from "@/components/layout/layout-header";
import Footer from "@/components/layout/layout-footer";
import layoutRight from '@/components/layout/layout-right';
import commonTitle from '@/components/common-title';
import companyInfo from '@/components/company-info.vue';
import hotTopicItem from '@/components/hot-topic-item.vue';
import courseItem from '@/components/courser-list-item';
import { liveJumpDetail } from '@/utils/jumpPageMethods';
import { dateFormat, UTCToLocalTimeString } from '@/utils/dateFormat.js';
import { jsonFormat } from '@/utils/jsonFormat';
import storage from 'store';

export default {
    name: "index",
    components: {
        Header, Footer, layoutRight, commonTitle, companyInfo,hotTopicItem,courseItem
    },
    data() {
        return {
            companyDetail:{}, //机构内容
            navList: companyNavList(this.$route.query.company_id),//顶部菜单
            navCurrentId: 0,//当前页面菜单对应下标
            newsList:[], //资讯列表
            productList:[], //产品列表
            zhaopinList:[], //招聘列表
            
            loading: false,
            layoutRightConfig: {
                meeting: { //会议
                    show: true, // 是否显示
                    title: '会议', // 标题
                    methods: 'get',
                    moreLink:'/company/meeting?company_id=' + this.$route.query.company_id,
                    data: {
                        page: 1,
                        pageSize: 5,
                        committee_id: this.$route.query.company_id
                    },   // 参数条件
                },
                live: { //直播
                    show: true, // 是否显示
                    methods: 'get',
                    moreLink:'/company/live?company_id=' + this.$route.query.company_id,
                    title: '直播', // 标题
                    data: {
                        page: 1,
                        limit: 3,
                        committee_id: this.$route.query.company_id,
                        no_end_time: 1
                    },   // 参数条件
                },
                orgMeetback: {
                    show: true, // 是否显示
                    title: '视频', // 标题
                    methods: 'get',
                    moreLink:'/company/video?company_id=' + this.$route.query.company_id,
                    data: {
                        page: 1,
                        limit: 3,
                        committee_id_search: this.$route.query.company_id,
                    },   // 参数条件
                },

            },
        }
    },
    created() {
    },
    methods: {
        handleCompanySuccess(){
            this.pageInit()
        },
        liveJumpDetail,
        // 资讯
        getNewsList(){
            return this.request.post('GetNews',{
                page: 1,
				limit: 3,
				committee_id:this.$route.query.company_id,
				type : 5,//必传
				is_verify : 1,//必传 https://docs.qq.com/doc/DY2lGaXdVelRrQnJP
            })
        },
        // 产品
        getProductList(){
            return this.request.post('CommitteeProductType',{
                page: 1,
				limit: 4,
				committee_id:this.$route.query.company_id,
            })
        },
        // 招聘
        getZhaopinList(){
            return this.request.post('CommitteeRecruit',{
                page: 1,
				limit: 3,
				committee_id:this.$route.query.company_id,
                title : ''
            })
        },
        // 点击产品类别
        handleProductClick(e){
            this.$router.push({ 
                path: '/company/product-list',
                query: {
                    company_id:this.$route.query.company_id,
                    class_id:e.id,
                    title:e.title
                }
            });
        },
        pageInit() {
            this.loading = true
            let reqArr = [
                this.getNewsList(), //资讯
                this.getProductList(), //产品
                this.getZhaopinList(), //招聘
            ]
            Promise.all(reqArr).then(res => {
                // 资讯
                this.newsList = res[0].data.list

                // 产品
                this.productList = res[1].data.list
                // 招聘
                res[2].data.list.forEach(item=>{
                    if(item.images && item.images.length > 0){
                        item.infomation_type = '2'
                    }
                })
                let obj = {
                    news_title: 'title',
                    browsing:'hits',
                }
                this.zhaopinList = jsonFormat(res[2].data.list, obj)

                this.loading = false
            }).catch((err) => { console.log(err) })
        },
        // 预约直播
        appointLive(){
            let { live_id } = this.recommendFirst
            this.request.post('AppletAppointment',{
                pages_url: "/live-pages/live-play/live-play?id=" + live_id,
                type: 1,
                type_id: live_id,
                tj_token: storage.get('tid')
            }).then(res=>{
                this.recommendFirst.applet_remind = 1
            })
        }
    }
}
</script>

<style scoped lang="less">

.homepage-header {
    width: 1200px;
    margin: 0 auto;

    &-title {
        max-width: 200px;
    }

    &-img {
        width: 88px;
        height: 88px;
        background: red;
    }
}
.company-info{
    width: 1240px;
    margin: 0 auto 20px;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
    &-item{
        img{
            width: 80px;
            height: 80px;
            border-radius: 50%;
        }
    }
}
.homepage-content {
    width: 1240px;
    margin: 0 auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
   
}
.homepage-list {
    margin-right: 30px;
    .mh{
        min-height: 500px;
    }
    .homepage-left{
        /deep/.component-box .course-block .course-item .course-info{
            height: 70px;
        }
    }
    
}
.homepage-right {
    width:400px;
}
// 直播推荐
.recommend-first {
    width: 50%;
    cursor: pointer;
    position: relative;
    .rf-image {
        width: 100%;
        height: 208px;
    }
    .rf-title{
        position: absolute;
        bottom: 67px;
        background: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 35px;
        line-height: 35px;
        box-sizing: border-box;
    }
    .rf-info {
        height: 67px;
        padding: 10px 20px;
        box-sizing: border-box;
        background: #1c2769;

        .rf-info-time {
            margin-top: 5px;

            b {
                font-size: 18px;
                color: #ffa45d;
                font-weight: bold;
                background: #2f3c8a;
                border-radius: 5px;
                padding: 3px;
            }

            sub {
                font-size: 12px;
                color: #fff;
                margin: 0 5px;
                opacity: 0.7;
            }
        }

        .rf-info-btn {
            padding: 6px 15px;
            border-radius: 35px;
            line-height: 20px;
            height: 32px;
            font-size: 12px;
            color: #fff;
        }

        .live-start {
            background: linear-gradient(to right, #f5bf58, #f2873b);
        }

        .living {
            background: linear-gradient(to right, #5f96e6, #1e51c9);
        }

        .lived {
            background: linear-gradient(to right, rgb(87, 188, 211), #1eabc9);
        }
        .live-appointed{
            background: #cccccc;
        }
    }
}
.hlf-news{
    width: 325px;
}
@media screen and (max-width:768px){
    .homepage-content{
        display: block;
        padding: 10px;
        width: 100%;
        .homepage-list{
            display: block;
            margin-right: 0;
            .homepage-left{
                display: block;
                .recommend-first{
                    width: 100%;
                    .rf-image{
                        height: auto;
                        object-fit: contain!important;
                    }
                }
                .hlf-news{
                    width: 100%;
                    margin-left: 0;
                    margin-top: 10px;
                    padding: 0 10px;
                }
            }
        }
        .homepage-right{
            width: 100%;
            margin-top: 50px;
        }
    }
}
</style>