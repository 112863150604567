<template>
    <a class="info-item flex x-between pt5 pb5 pl10 pr10 y-center">
        <div class="flex x-left y-center">
            <img class="info-item-avatar" src="https://files.sciconf.cn/medcon/2022/08/20220810/2022081016205936857410219.jpg" alt="" />
            <div class="info-item-text flex-column x-between t-l ml20">
                <span class="color000 fs14">哈哈哈哈</span>
                <span class="color999 fs12">四川大学华西医院康复医疗中心</span>
            </div>
        </div>
        <div class="info-item-btn t-c fs12 flex y-center x-center">前往专家主页</div>
    </a>
</template>

<script>
export default {
    name: "text-item",
    props: {
        type:{
            type:String,
            default:''
        },
        dataList: {
            type: Object,
            defalut: () => {
                // expert_avatar:"",
                //   id:1,
                //   class_type:1, // 对应徽章的类型 颜色
                //   typeName:'指南',  // 对应徽章的文字
                //   title:'',  
                //   date:'',
                //   desc:'',
            }
        },
    },
    data() {
        return {
            
        }
    },
    created(){
        console.log(this.dataList)
    },
};
</script>

<style scoped lang="less">
.info-item{
    width: 100%;
    height: 80px;
    border: 1px solid #eeeeee;
    &-text{
        height: 50px;
    }
    &-avatar{
        width: 70px;
        height: 70px;
        object-fit: cover;
        object-position: top;
        border-radius: 50%;
    }
    &-btn{
        width: 100px;
        height: 30px;
        border-radius: 20px;
        border: 1px solid #3c56d3;
        color: #3c56d3;
        cursor: pointer;
        &:hover{
            background: #3c56d3;
            color: #ffffff;
        }
    }
}
</style>
