<template>
    <div class="box">
        <company-info :navLists="navList" :navCurrentIds="navCurrentId"></company-info>
        <div class="news-detail flex">
            <div class="guide-list-left" :class="[{ 'news-detail-width' : pageType != 'news' }]">
                <a-spin :spinning="loading" tip="Loading...">
                    <template v-if="pageType == 'news'">
                        <!-- 文章详情 -->
                        <div class="guidelist-article mt10 t-l pb20" v-if="infomation">
                            <p class="fs22 strong color333 row-2">{{ infomation.news_title }}</p>
                            <p class="fs12 color999 row-1 mt20">
                                <span>{{ infomation.create_time }}</span>
                                <span class="ml20" v-if="infomation.source"><span class="strong">来源：</span>{{
                                    infomation.source
                                }}</span>
                                <span class="ml20" v-if="infomation.browsing"><span class="strong">阅读：</span>{{
                                    infomation.browsing
                                }}次</span>
                            </p>
                        </div>
                        <!-- <info-item></info-item> -->
                        <div v-if="infomation.news_content" v-html="infomation.news_content"
                            class="t-l mt15 content-art mb20" @click="handleFollowEvent($event)"></div>
                        <!--热点资讯点赞收藏  -->
                        <div v-if="type == 2 && infomation.news_content" class="flex x-right">
                            <span class="ml15 cursor" @click="getExeLikeCollect('2')">
                                <i :class="['med', infomation.is_like == 1 ? 'med-dianzan' : 'med-dianzan_huaban']"></i>
                                <span class="ml5">{{ infomation.is_like == 1 ? '已点赞' : '点赞' }}</span>
                            </span>
                            <span class="ml15 cursor" @click="getExeLikeCollect('1')">
                                <i :class="['med', infomation.is_collect == 1 ? 'med-shoucang1' : 'med-shoucang']"></i>
                                <span class="ml5">{{ infomation.is_collect == 1 ? '已收藏' : '收藏' }}</span>
                            </span>
                        </div>
                        <a-empty class="mb10" style="margin-top:100px;" v-if="!infomation.news_content && !loading" />
                    </template>
                    <template v-if="pageType == 'product' || pageType == 'zhaopin'">
                        <div class="guidelist-article mt10 t-l pb20" v-if="otherDetail">
                            <p class="fs22 strong color333 row-2">{{ otherDetail.news_title }}</p>
                            <p class="fs12 color999 row-1 mt20">
                                <span><span class="strong" v-if="otherDetail.create_time">发布日期：</span>{{ otherDetail.create_time }}</span>
                                <span class="ml20" v-if="otherDetail.browsing"><span class="strong">浏览：</span>{{
                                    otherDetail.browsing
                                }}次</span>
                            </p>
                        </div>
                        <template v-if="otherDetail.news_content">
                            <img v-if="otherDetail.product_img" style="max-width:100%;" :src="otherDetail.product_img " alt="">
                            <div v-html="otherDetail.news_content" class="t-l mt15 content-art mb20"></div>
                        </template>
                        
                    </template>
                </a-spin>
            </div>
            <div class="guide-list-right mt20" v-if="pageType == 'news'">
                <layout-right :config="layoutRightConfig"></layout-right>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import { companyNavList } from "@/config/const";
import layoutRight from '@/components/layout/layout-right';
import companyInfo from '@/components/company-info.vue';
import Header from "@/components/layout/layout-header";
import Footer from "@/components/layout/layout-footer";
import commonTitle from '@/components/common-title';
import commentList from '@/components/comment-list.vue';
import acrossMediaItem from '@/components/across-media-item.vue';
import liveItem from '@/components/live-item.vue';
import infoItem from '@/components/info-item.vue';
import { urlReplace } from '@/utils/urlReplace';
import verticalMediaItem from '@/components/vertical-media-item.vue';
import * as videoObject from '@/components/video-player/video-play';
import { expertJumpDetail } from '@/utils/jumpPageMethods'
import storage from 'store';
import { mapState } from 'vuex';
let layoutRightConfig = {
    // correlationNews: {
    //     show: true, // 是否显示
    //     title: '相关指南', // 标题
    //     methods: 'get',
    //     data: {
    //         page: 1,
    //         pageSize: 5,
    //         subject_ids: '',
    //         keyword: ''
    //     },   // 参数条件
    // }, //相关资讯
    correlationMeet: { //相关会议
        show: true, // 是否显示
        title: '推荐会议', // 标题
        methods: 'get',
        data: {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            page: 1,
            pageSize: 3,
            subject_ids: '',
            keyword: ''
        },   // 参数条件
    },
    correlationLives: { //相关直播
        show: true, // 是否显示
        methods: 'get',
        title: '推荐直播', // 标题
        data: {
            tj_token: storage.get('tid'),
            page: 1,
            limit: 3,
            subject_ids: '',
            keyword: ''
        },   // 参数条件
    },
    // 相关视频
    correlationVideo: {
        show: true, // 是否显示
        methods: 'get',
        title: '推荐视频', // 标题
        data: {
            page: 1,
            pageSize: 4,
            subject_ids: '',
            keyword: ''
        },   // 参数条件
    },
    correlationInfo: null,
    // 相关视频
    // correlationInfo: {
    // show: true, // 是否显示
    // methods: '',
    // title: '', // 标题
    // data: {
    //     page: 1,
    //     limit: 4,
    // },   // 参数条件
    // limit:'', //会议资讯及相关资讯为1 指南解读为2 推荐指南为3
    // },

}
export default {
    name: 'CompanyNewsDetail',
    components: {
        Header, Footer, companyInfo, layoutRight, commonTitle, commentList, acrossMediaItem, infoItem, liveItem, verticalMediaItem
    },
    data() {
        return {
            pageType:this.$route.query.pageType,
            pageId:this.$route.query.id,
            navList: companyNavList(this.$route.query.company_id),//顶部菜单
            navCurrentId: 1,//当前页面菜单对应下标
            layoutRightConfig: null,
            newId: this.$route.query.id,
            type: this.$route.query.type,
            // newId:'20282',
            // 文章详情
            infomation: {},
            otherDetail:{},
            next: false,
            loading: true,
            newss: '',
            userUid: [],
            followList: [],
            isGuide: false,
            meetingId: '',
            guideId: 0,
            correlationList: [], //相关资讯
        }
    },
    created() {
        if(this.pageType == 'news'){
            this.navCurrentId = 1
            this.getInfoDetail()
        }else if(this.pageType == 'product'){
            this.navCurrentId = 2
            this.getProductDetail()
        }else if(this.pageType == 'zhaopin'){
            this.navCurrentId = 6
            this.getZhaopinDetail()
        }
        
    },
    computed: {
        ...mapState(['userInfo'])
    },
    watch: {
        $route(to, from) {
            if (to.fullPath != from.fullPath) {
                this.layoutRightConfig = null
                this.newId = to.query.id
                this.type = to.query.type
                this.getInfoDetail()
            }

        }
    },
    methods: {
        expertJumpDetail,
        handleFollowEvent(e) {
            let id = e.target.id
            if (id && id.indexOf('follow-btn-') > -1) {
                let index = id.lastIndexOf("-");
                index = id.substr(index + 1, id.length)
                if (this.followList[index] == 0) {
                    this.request.post('FollowExperts', { experts_uid: this.userUid[index] }).then(res => {
                        if (res.data.code == 200) {
                            this.getInfoDetail()
                        }
                    })
                }
            }

        },
        getInfoDetail() {
            this.loading = true
            let data = {
                new_id: this.newId,
                type: this.type
            }
            this.request.post('GetNewsDetail', data).then(res => {
                if (res.data.keywords) {
                    for (let key in layoutRightConfig) {
                        key != 'correlationInfo' && (layoutRightConfig[key].data.keyword = res.data.keywords)
                    }
                }
                if (res.data.subject_ids) {
                    for (let key in layoutRightConfig) {
                        key != 'correlationInfo' && (layoutRightConfig[key].data.subject_ids = res.data.subject_ids || '')
                    }
                }

                // 相关资讯、指南等展示逻辑

                let dataGuide = {}
                this.meetingId = res.data.meeting_id

                dataGuide['subject_ids'] = res.data.subject_ids || '';
                dataGuide['keyword'] = res.data.keywords || '';
                this.isGuide && (dataGuide['rm_guide_id'] = this.guideId)
                let url = this.isGuide ? 'GuideRelatedVideo' : 'GetRecommendGuidesV2';
                if (!this.meetingId || this.meetingId == 0) {
                    this.request.get(url, { page: 1, pageSize: 5, ...dataGuide }).then(res_data => {
                        if (url == 'GetRecommendGuidesV2' && !res_data.data.list.length) {
                            this.meetingId = '99999'
                        }
                        this.getRequestList(res.data.keywords, dataGuide, res_data.data.type)
                        this.layoutRightConfig = layoutRightConfig;
                    }).catch(err => {
                        this.getRequestList(res.data.keywords, dataGuide)
                        this.layoutRightConfig = layoutRightConfig;
                    })
                } else {
                    this.getRequestList(res.data.keywords, dataGuide)
                    this.layoutRightConfig = layoutRightConfig;
                }


                // this.request.get(url, { page: 1, pageSize: 5, ...dataGuide }).then(res_data => {
                //     if (url == 'GetRecommendGuidesV2' && !res_data.data.list.length) {
                //         this.meetingId = '99999'
                //         this.getRequestList(res.data.keywords, dataGuide)
                //     } else {
                //         this.getRequestList(res.data.keywords, dataGuide)
                //     }
                //     this.layoutRightConfig = layoutRightConfig;
                // }).catch(err => {
                //     this.getRequestList(res.data.keywords, dataGuide)
                //     this.layoutRightConfig = layoutRightConfig;
                // })
                if (res.data && res.data != '') {
                    this.infomation = res.data
                    let { news_content, expert_avatar, expert_user_id, expert_org, expert_name } = this.infomation;
                    let regx = /\[\[\[.*?\]\]\]/g;
                    let gzbtn = ''
                    let newss = ''
                    // let news = '[[[expert-person]]][[[expert-person="cDvwdYms1M3r9wSD1Gr2qQ_d_d"]]][[[expert-person="hWCXEiM_xNWqGZskDnzTd8A_d_d"]]]'
                    news_content = news_content.replace(/\/search-pages\/roster\/roster/g,'/#/job-warehouse/rollCall');
                    this.infomation.news_content = news_content
                    let userList = this.infomation.news_content.match(regx)
                    // console.log(userList)
                    if (userList && userList.length > 0) {
                        for (var i = 0; i < userList.length; i++) {
                            let index = i
                            let hasUid = userList[i].indexOf("=")
                            if (hasUid != -1) {
                                userList[i] = userList[i].match(/"(\S*)"/)[1]
                                this.userUid[i] = userList[i]
                            } else {
                                this.userUid[i] = expert_user_id
                            }
                            this.request.post('ExpertsData', { experts_uid: this.userUid[i] }).then(res => {
                                this.followList[index] = res.data.fans_status
                                let { avatar, real_name, org_cnname, user_id } = res.data
                                if (avatar == '') {
                                    avatar = require('@/assets/images/person-default.png')
                                }
                                if (!this.followList[index]) {
                                    gzbtn = '<span class="ml10" id="follow-btn-' + index + '" style="border:1px solid #c00000;padding:2px 10px;border-radius:5px;color:#c00000;cursor:pointer;">+关注</span>'
                                } else {
                                    gzbtn = '<span class="ml10" id="follow-btn-' + index + '" style="border:1px solid #cccccc;padding:2px 10px;border-radius:5px;color:#cccccc;">已关注</span>'
                                }
                                let origin = window.location.origin;
                                newss = '<div style="width: 100%;height: 80px;border: 1px solid #eeeeee;border-radius:10px;" class="info-item flex x-between mb20 pt5 pb5 pl10 pr10 y-center"><div class="flex x-left y-center"><img style="width: 70px;height: 70px;object-fit: cover;object-position: top;border-radius: 50%;float:left;" class="info-item-avatar" src=' + avatar + ' alt="" /><div style="height: 50px;float:left;margin-left:20px;margin-top:10px;" class="info-item-text flex-column x-between t-l ml20"><p style="margin-bottom:0;"><span class="color000 fs14">' + real_name + '</span>' + gzbtn + '</p><span class="color999 fs12">' + org_cnname + '</span></div></div><a href=/#/person-detail?uid=' + user_id + ' style="width: 100px;height: 30px;border-radius: 20px;border: 1px solid #3c56d3;color: #3c56d3;cursor: pointer;" class="info-item-btn t-c fs12 flex y-center x-center">前往专家主页</a></div>'
                                if (hasUid != -1) {
                                    this.infomation.news_content = this.infomation.news_content.replace('[[[expert-person="' + userList[index] + '"]]]', newss)
                                } else {
                                    this.infomation.news_content = this.infomation.news_content.replace('[[[expert-person]]]', newss)
                                }
                                this.infomation.news_content = urlReplace(this.infomation.news_content, (vid, el) => {
                                    videoObject.init(el, vid);
                                })
                            })
                        }
                    } else {
                        this.infomation.news_content = urlReplace(this.infomation.news_content, (vid, el) => {
                            videoObject.init(el, vid);
                        })
                    }
                } else {
                    this.infomation = {}
                }
                this.loading = false

            })
        },
        getRequestList(keywords, dataGuide, type) {
            let title, limit, methods, moreLink
            let data = {}
            if (this.meetingId && this.meetingId != '0') {
                title = this.meetingId == '99999' ? '相关资讯' : '会议资讯'
                data = this.meetingId == '99999' ? { page: 1, limit: 3, keywords: keywords || '' } : { page: 1, limit: 3, meeting_id: this.meetingId, }
                limit = 1
                methods = 'post'
                // let type = this.type == '2' ? '1' : this.type == '6' ? '2' : '3'
                moreLink = '/infomation-list?index=' + this.type
            } else {
                title = this.isGuide ? '指南解读' : '推荐指南'
                limit = this.isGuide ? 2 : type == 'video' ? 4 : 3
                methods = 'get'
                let pageSize = this.isGuide || type == 'video' ? 4 : 5
                data = { page: 1, pageSize, ...dataGuide }
                moreLink = this.isGuide || type == 'video' ? 'video-list' : '/guide-list'
            }
            layoutRightConfig['correlationInfo'] = {
                show: true,
                title,
                data,
                methods,
                limit,
                moreLink
            }
        },
        // 点赞收藏
        getExeLikeCollect(operate) {
            this.request.post('GetExeLikeCollect', {
                id: this.infomation.id,
                type: 2,
                operate
            }).then(res => {
                // 收藏1 点赞2
                if (operate == 1) {
                    let is_collect = this.infomation.is_collect
                    if (is_collect) {
                        is_collect = 0
                    } else {
                        is_collect = 1
                    }
                    this.infomation.is_collect = is_collect
                } else {
                    let is_like = this.infomation.is_like
                    if (is_like) {
                        is_like = 0
                    } else {
                        is_like = 1
                    }
                    this.infomation.is_like = is_like
                }
            })
        },
        getProductDetail(){
            this.loading = true
            this.request.post('CommitteeProduct', {
                p_id : this.pageId
            }).then(res => {
                if(res?.data){
                    let newResObj = {
                        news_title : res.data.title, //名称
                        browsing : res.data.hits,//销量
                        news_content : res.data.desc,//详情
                        create_time : res.data.create_time ,//发布日期
                        product_img : res.data.index_img_url,//商品图
                    }
                    this.otherDetail = newResObj
                }   
                

                this.loading = false
            })
        },
        getZhaopinDetail(){
            this.loading = true
            this.request.post('CommitteeRecruitDetail', {
                r_id : this.pageId
            }).then(res => {
                let newResObj = {
                    news_title : res.data.title, //名称
                    browsing : res.data.hits,//销量
                    news_content : res.data.content,//详情
                    create_time : res.data.create_time ,//发布日期
                }
                this.otherDetail = newResObj

                this.loading = false
            })
        }
    }
}
</script>
<style lang="less" scoped>
.news-detail {
    width: 1240px;
    margin: 0 auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
}

.guide-list-left {
    width: 770px;

    .guidelist-breadcrumb {
        width: 300px;
        height: 20px;

        .guide-ant-breadcrumb {
            float: left;
            color: #c1c1c1;
        }
    }

    .guidelist-article {
        width: 100%;
        border-bottom: 1px dashed #d8d8d8;

        p {
            margin-bottom: 0;
        }
    }

    .content-art {
        width: 100%;

        /deep/ img {
            max-width: 100%;
            height: auto;
        }
    }

    .guidelist-download {
        width: 100%;
        height: 44px;
        cursor: pointer;

        .color-member {
            color: #1e51c9;
        }

        .download-read {
            width: 104px;
            height: 44px;
            background: linear-gradient(to right, #5f96e6, #1e51c9);
            border-radius: 5px;
            text-align: center;
            line-height: 44px;
        }

        .download-read-new {
            height: 40px;
            background: linear-gradient(to right, #5f96e6, #1e51c9);
            border-radius: 5px;
            text-align: center;
            line-height: 40px;
            padding: 0 10px 0 10px;
        }
    }

    .guidelist-banner {
        width: 100%;
        height: 187px;
    }

    .med-shoucang1,
    .med-dianzan {
        color: #FFA45D;
    }

    .cursor {
        cursor: pointer;
    }

    .correlation-box {
        width: 100%;
        height: auto;
        border: 1px solid #1E51C9;
        padding: 10px;

        .correlation-bg {
            width: 100%;
            height: auto;
            background: #f6f8fc;
            padding: 10px;

            .correlation-title {
                border-bottom: 1px solid #1E51C9;
                display: inline-block;
                line-height: 30px;
            }
        }
    }
}
.news-detail-width{
    width: 100%;
}

.guide-list-right {
    width: 398px;
}

@media screen and (max-width:768px) {
    .news-detail {
        display: block;
        padding: 10px;
        width: 100%;
        .guide-list-left {
            width: 100%;
        }
        .guide-list-right {
            width: 100%;
            margin-top: 60px;
        }
    }
}</style>
