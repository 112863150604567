<template>
    <div>
        <div class="background"></div>
        <div v-if="companyDetail" :class="[{'mobile-mt':hasFilter}]">
            <div class="homepage-header flex x-left">
                <Header :companyLogo="companyDetail?.committee?.index_logo || require('@/assets/images/committee-logo.jpg')" :navList="navLists" :navCurrentId="navCurrentIds">
                    <div slot="search">
                        <div style="width:150px;"></div>
                    </div>
                </Header>
            </div>
            <div class="company-info mb30">
                <div class="company-info-item flex x-left">
                    <!-- <img :src="companyDetail?.committee?.index_logo || require('@/assets/images/committee-logo.jpg')" alt=""> -->
                    <div class="info flex-column x-between">
                        <div class="flex x-left y-center">
                            <div class="name fs18 strong mr5 t-l">{{ companyDetail?.committee?.name }}</div>
                            <div v-if="companyDetail.fans_status == 1" class="unfollow fs12" @click="handleFollowCompany">取消关注</div>
                            <div v-else class="follow fs12" @click="handleFollowCompany">+关注</div>
                        </div>
                        <div class="company-info-desc mt10">
                            <div ref="desc_info" class="t-l" :class="!isShowMore ? 'desc row-2' : 'desc-msg-more'" v-html="companyDetail.desc"></div>
                            <span v-if="hasMore" class="cursor t-r check-all-btn" @click="moreInfo">查看详情</span>
                            <span v-if="!hasMore && isShowMore" class="cursor t-r check-all-btn" @click="moreInfo">收起</span>
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </div>
    </div>
    
</template>

<script>
import Header from "@/components/layout/layout-header";
export default {
    name: "",
    props: {
        navLists: {
            type: Array,
            default: () => [],
        },
        hasFilter:{
            type:Boolean,
            default: () => false,
        },
        navCurrentIds:{
            type: [Number, String],
            default: 0,
        },
    },
    data() {
        return {
            companyDetail:{},
            isShowMore: false, //是否显示收起
            hasMore: false, //是否需要显示查看更多
        }
    },
    components: {
        Header
    },
    computed: {
        
    },
    created() {
        this.$store.dispatch('getCompanyDetail',this.$route.query.company_id).then(res => {
            this.companyDetail = res || {}
            this.$emit('success');
            // 提示信息是否有查看全部
            this.$nextTick(() => {
                this.companyDetail.desc && (this.hasMore = this.$refs.desc_info.clientHeight < this.$refs.desc_info.scrollHeight)
            })
        })
        
    },
    methods: {
        // 关注企业
        handleFollowCompany(){
            this.request.post('FollowCommittee',{
                committee_id: this.$route.query.company_id
            }).then(res=>{
                let { fans_status,fans_count } = this.companyDetail;
                if(fans_status){
                    fans_count = parseInt(fans_count) - 1;
                }else{
                    fans_count = parseInt(fans_count) + 1;
                }
                this.companyDetail.fans_count = fans_count
                this.companyDetail.fans_status = !fans_status
            })
        },
        // 提示信息查看全部
        moreInfo() {
            this.isShowMore = !this.isShowMore
            this.hasMore = !this.hasMore
        },
        
    },
    mounted() {

    }
};
</script>

<style scoped lang="less">
.background {
    width: 100%;
    height: 500px;
    background: linear-gradient(360deg, rgba(234, 249, 255, 0) 0%, #EAFAFF 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.homepage-header {
    width: 1200px;
    margin: 0 auto;

    &-title {
        max-width: 200px;
    }

    &-img {
        width: 88px;
        height: 88px;
        background: red;
    }
}
.company-info{
    width: 1240px;
    margin: 0 auto 20px;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
    &-item{
        img{
            width: 80px;
            height: 80px;
        }
        .unfollow{
            background: #f1f1f1;
            color: #777777;
            padding: 5px 10px;
            border-radius: 20px;
            cursor: pointer;
        }
        .follow{
            color: #ffffff;
            background: #fab02f;
            padding: 5px 10px;
            border-radius: 20px;
            cursor: pointer;
        }
    }
    &-desc{
        line-height: 1.5;
        position: relative;
        .desc{
            max-height: 43px;
        }
        .desc-msg-more{
            height: auto;
        }
        span{
            position: absolute;
            bottom: 0;
            right: 0;
            background: #ffffff;
            padding: 0 10px;
            line-height: 1.5;
            color: #1E51C9;
            cursor: pointer;
        }
    }
}
@media screen and (max-width:768px) {
    .company-info{
        width: 100%;
        padding: 20px 10px;
        // margin-top: 54px;
        border-radius: 0;
        &-item{
            .info{
                .name{
                    flex: 1;
                }
            }
            .follow,.unfollow{
                width: 70px;
            }
        }
    }
    .mobile-mt{
        margin-top: 50px;
    }

}
</style>
