import { render, staticRenderFns } from "./filter-tab.vue?vue&type=template&id=55a7d981&scoped=true&"
import script from "./filter-tab.vue?vue&type=script&lang=js&"
export * from "./filter-tab.vue?vue&type=script&lang=js&"
import style0 from "./filter-tab.vue?vue&type=style&index=0&id=55a7d981&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55a7d981",
  null
  
)

export default component.exports